import { useThree } from "@react-three/fiber";
import { useEffect, useRef } from "react";
import gsap from "gsap";
import { isMobile } from "react-device-detect";

const CameraSecondAnimation = ({
  refCamera,
  // setStartThirdAnimation,
  startUltimateAnimation,
  setFinishAnimationsCamera,
}) => {
  const refInit = useRef(false);
  useEffect(() => {
    if (refInit.current) return;
    if (!startUltimateAnimation) return;
    refInit.current = true;
    // cameraX: { value: -30, min: -40, max: 0, label: "Camera X" },
    // cameraY: { value: 20.5, min: 0, max: 30, label: "Camera Y" },
    // cameraZ: { value: 6, min: 0, max: 15, label: "Camera Z" },

    if (isMobile) {
      gsap.to(refCamera.current.position, {
        // x: -16,
        x: -16,
        y: 16,
        // z: 7.37,
        z: 9.5,
        duration: 1.7,
        ease: "power1.inOut",
        onComplete: () => {
          // setStartThirdAnimation(true);
          setFinishAnimationsCamera(true);
        },
      });
    } else {
      // -24, 17.5, 2
      gsap.to(refCamera.current.position, {
        // x: -34,
        x: -24,
        // y: 17.5,
        y: 15,
        z: 2,
        // duration: 0.5,
        duration: 4.6,
        ease: "power1.inOut",
        onComplete: () => {
          // setStartThirdAnimation(true);
          setFinishAnimationsCamera(true);
        },
      });
    }
  }, [startUltimateAnimation]);
  return null;
};

export default CameraSecondAnimation;
