/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 -i toy_box.glb --transform
Author: Hayley Jacka (https://sketchfab.com/HayleyJacka)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/toy-box-8ce01f03c4c54c1bb69f6ba47a590586
Title: Toy Box
*/

import React, { useEffect, useRef, useState } from 'react'
import { useGLTF, useTexture } from '@react-three/drei'
import * as THREE from 'three'
import { useFrame } from '@react-three/fiber';

export function Model(props) {
  const [wall1, setWall1] = useState(null);
  const refWall = useRef()
  const { nodes, materials } = useGLTF('/models/toy_box-transformed.glb')
 
  const textureRug = materials.Mat_Rug.map;
//   const materialRug = new THREE.MeshStandardMaterial({ 
//     map: textureRug,
//     roughness: 0.5,
//     metalness: 0.5,
//   });

// const materialPhysical = new THREE.MeshPhysicalMaterial({
//   color: props.controlsScene.colorWall,
//   side: THREE.DoubleSide,
//   roughness: props.controlsScene.roughnessWall,
//   metalness: props.controlsScene.metalnessWall,
//   emissive: props.controlsScene.emissive,
//   emissiveIntensity: props.controlsScene.emissiveIntensity,
//   thickness: 0.5,
//   transmission: 1,
// });
// const materialTecho = new THREE.MeshPhysicalMaterial({
//   color: props.controlsScene.colorTecho,
//   side: THREE.DoubleSide,
//   roughness: 0.5,
//   metalness: 0.5,
//   emissive:props.controlsScene.colorTechoEmisive,
//   emissiveIntensity: props.controlsScene.colorTechoEmissiveIntensity,
//   thickness: 0.5,
//   transmission: 1,
// });
  const materialRug = new THREE.MeshStandardMaterial({ 
    map: textureRug,
    roughness: 0.5,
    metalness: 0.5,
  });


// const materialTecho = new THREE.MeshMatcapMaterial({
//   color: props.controlsScene.colorTecho,
//   side: THREE.DoubleSide,
//   // roughness: 0.5,
//   // metalness: 0.5,
//   // emissive:props.controlsScene.colorTechoEmisive,
//   // emissiveIntensity: props.controlsScene.colorTechoEmissiveIntensity,
// });
const materialTecho = props.materialWall;

// const textureFloor =  materials.Mat_Room.map;

const materialFloor =  materials.Mat_Room;
// const materialFloor = new THREE.MeshStandardMaterial({
//   color: props.controlsScene.colorFloor || new THREE.Color(0x333333), // Color oscuro por defecto
//   side: THREE.DoubleSide,
//   roughness: props.controlsScene.roughnessFloor,
//   metalness: props.controlsScene.metalnessFloor,
//   emissive: props.controlsScene.emissiveFloor,
//   emissiveIntensity: props.controlsScene.emissiveIntensityFloor,
//   thickness: 1,
//   transmission: 1,
//   map: textureFloor
// });

  return (
    <group {...props} dispose={null} rotation={[0,Math.PI * 1.5,0]} scale={0.4}
    position={[-70,10,-13]}
    >
      {/* Nueva pared */}
      <mesh  position={[217,0,0]}  rotation={[0, 0, 0]}    
      depthWrite={false} receiveShadow castShadow 
      geometry={nodes.Wall2_Mat_Room_0.geometry} 
     
        material={ props.materialWall}
      />
      <mesh  position={[217,0,-181.5]}  rotation={[0, 0, 0]}    
      depthWrite={false} receiveShadow castShadow 
      geometry={nodes.Wall2_Mat_Room_0.geometry} 
     
        material={ props.materialWall}
      />
      <mesh  position={[217,0,-363]}  rotation={[0, 0, 0]}    
      depthWrite={false} receiveShadow castShadow 
      geometry={nodes.Wall2_Mat_Room_0.geometry} 
     
        material={ props.materialWall}
      />
    
     
      
      {/* Fin nueva pared */}
      {/* Segunda pared */}
      {/* <mesh material={materialPhysical} depthWrite={false} receiveShadow castShadow geometry={nodes.Wall2_Mat_Room_0.geometry}
        /> */}
      <mesh position={[0,0,-181.5]} material={ props.materialWall} depthWrite={false} receiveShadow castShadow geometry={nodes.Wall2_Mat_Room_0.geometry}
        />
    
      <mesh position={[0,0,-363]} material={ props.materialWall} depthWrite={false} receiveShadow castShadow geometry={nodes.Wall2_Mat_Room_0.geometry}
        />
    
      {/* Fin pared */}
      {/* <mesh depthWrite={false} receiveShadow castShadow geometry={nodes.Wall2_Mat_Room_0.geometry} material={materials.Mat_Room} /> */}
      {/* Pared de fondo */}
      <mesh depthWrite={false} receiveShadow castShadow geometry={nodes.Wall1_Mat_Room_0.geometry} 
      // material={materials.Mat_Room}
      material={ props.materialWall}
       >
          {/* <meshStandardMaterial color={props.controlsScene.colorWall} 
       side={THREE.DoubleSide}
       roughness={props.controlsScene.roughnessWall}
       metalness={props.controlsScene.metalnessWall}
       /> */}

{/* <meshPhysicalMaterial color={props.controlsScene.colorWall} side={THREE.DoubleSide}
        roughness={props.controlsScene.roughnessWall}
        metalness={props.controlsScene.metalnessWall}
        emissive={props.controlsScene.emissive}
      emissiveIntensity={props.controlsScene.emissiveIntensity}
      thickness={0.5}
      transmission={1}
        /> */}
      </mesh>
      {/* Techo */}
      {/* material={new THREE.MeshBasicMaterial({color:0xFF0000, side:THREE.DoubleSide})} */}
      <mesh position={[0,180,0]} depthWrite={false} receiveShadow  geometry={nodes.Floor_Mat_Room_0.geometry} material={materialTecho} />
      <mesh position={[0,180,-180]} depthWrite={false} receiveShadow  geometry={nodes.Floor_Mat_Room_0.geometry} material={materialTecho} />
      <mesh position={[0,180,-360]} depthWrite={false} receiveShadow  geometry={nodes.Floor_Mat_Room_0.geometry} material={materialTecho} />
      
      {/* Piso */}
      <mesh depthWrite={false} receiveShadow  geometry={nodes.Floor_Mat_Room_0.geometry} material={materials.Mat_Room} />
      <mesh position={[0,0,-181.5]} depthWrite={false} receiveShadow  geometry={nodes.Floor_Mat_Room_0.geometry} material={materialFloor} />
      <mesh position={[0,0,-363]} depthWrite={false} receiveShadow  geometry={nodes.Floor_Mat_Room_0.geometry} material={materialFloor} />

      {/* Fin piso */}
      {/* <mesh depthWrite={false} receiveShadow  geometry={nodes.WindowSil_Mat_Box_0.geometry} material={materials.Mat_Box} /> */}
      {/* Este es el plano que emite luz */}
      <mesh position={[props.controlsScene.emitX, props.controlsScene.emitY, props.controlsScene.emitZ]} scale={[1,1,1]} depthWrite={false}   geometry={nodes.WindowLight_Mat_Light_0.geometry} material={materials.Mat_Light} />
      {/* Ventana */}
      {/* <mesh depthWrite={false}   geometry={nodes.WindowFrame_Mat_Box_0.geometry}
       material={materials.Mat_Box} 
      
       >
        </mesh> */}
        {/* Luz */}
      {/* <mesh depthWrite={false}   geometry={nodes.Lightbeam2_Mat_Light_0.geometry} material={materials.Mat_Light} /> */}
      {/* <mesh depthWrite={false} receiveShadow castShadow geometry={nodes.WallTrim1_Mat_Room_0.geometry} material={materials.Mat_Room} />
      <mesh depthWrite={false} receiveShadow castShadow geometry={nodes.WallTrim2_Mat_Room_0.geometry} material={materials.Mat_Room} /> */}
      {/* Aca arriba */}
      {/* <group position={[5.88, -5.54, -11]} rotation={[0, -0.05, 0]} scale={1.31}>
        <mesh depthWrite={false} receiveShadow castShadow geometry={nodes.Tongue_Mat_Toys_0.geometry} material={materials.Mat_Toys} />
        <mesh depthWrite={false} receiveShadow castShadow geometry={nodes.EyeL_Mat_Toys_0.geometry} material={materials.Mat_Toys} />
        <mesh depthWrite={false} receiveShadow castShadow geometry={nodes.EyeR_Mat_Toys_0.geometry} material={materials.Mat_Toys} />
        <mesh depthWrite={false} receiveShadow castShadow geometry={nodes.SpaceDogRetop_Mat_Toys_0.geometry} material={materials.Mat_Toys} />
        <mesh depthWrite={false} receiveShadow castShadow geometry={nodes.Helmet_Mat_Toys_0.geometry} material={materials.Mat_Toys} />
      </group> */}
      <mesh depthWrite={false} receiveShadow castShadow geometry={nodes.Box_Base_Mat_Box_0.geometry} 
      material={materials.Mat_Box}
      // material={materialBox} 
      />
      <mesh depthWrite={false} receiveShadow castShadow geometry={nodes.Box_Lid_Mat_Box_0.geometry} 
      material={materials.Mat_Box} 
      // material={materialBox}
      />
      {/* Alfombra */}
      <mesh depthWrite={false} receiveShadow castShadow geometry={nodes.Rug_Mat_Rug_0.geometry} 
      // material={materials.Mat_Rug} 
      material={materialRug}
      />
      {/* Esta es la esfera */}
      {/* <mesh depthWrite={false}  receiveShadow castShadow geometry={nodes.Ball1_Mat_Toys_0.geometry}
       material={materials.Mat_Toys} 
      // material={plasticMaterial}
       /> */}
      {/* <mesh depthWrite={false} receiveShadow castShadow geometry={nodes.Ball2_Mat_Toys_0.geometry} material={materials.Mat_Toys} />
      <mesh depthWrite={false} receiveShadow castShadow geometry={nodes.Cookie1_Mat_Toys_0.geometry} material={materials.Mat_Toys} />
      <mesh depthWrite={false} receiveShadow castShadow geometry={nodes.Cookie2_Mat_Toys_0.geometry} material={materials.Mat_Toys} />
      <mesh depthWrite={false} receiveShadow castShadow geometry={nodes.Cheese_Mat_Toys_0.geometry} material={materials.Mat_Toys} />
      <mesh depthWrite={false} receiveShadow castShadow geometry={nodes.Toilet_Paper_Mat_Toys_0.geometry} material={materials.Mat_Toys} />
      <mesh depthWrite={false} receiveShadow castShadow geometry={nodes.Broccoli_Mat_Toys_0.geometry} material={materials.Mat_Toys} />
      <mesh depthWrite={false} receiveShadow castShadow geometry={nodes.Potato_Mat_Toys_0.geometry} material={materials.Mat_Toys} />
      <mesh depthWrite={false} receiveShadow castShadow geometry={nodes.Basket_Handle_Mat_Box_0.geometry} material={materials.Mat_Box} /> */}
      {/* Aca abajo esfera */}
      <mesh depthWrite={false} receiveShadow castShadow geometry={nodes.Basket1_Mat_Box_0.geometry} material={materials.Mat_Box} />
      {/* <mesh depthWrite={false} receiveShadow castShadow geometry={nodes.Sock_Mat_Toys_0.geometry} material={materials.Mat_Toys} /> */}
      {/* <mesh depthWrite={false} receiveShadow castShadow geometry={nodes.Sock1_Mat_Toys_0.geometry} material={materials.Mat_Toys} /> */}
      {/* <mesh depthWrite={false} receiveShadow castShadow geometry={nodes.PottedPlant_Mat_Toys_0.geometry} material={materials.Mat_Toys} /> */}
      {/* <mesh depthWrite={false} receiveShadow castShadow geometry={nodes.DogBowl_Mat_Toys_0.geometry} material={materials.Mat_Toys} /> */}
      {/* Bear */}
      <mesh depthWrite={false} receiveShadow castShadow geometry={nodes.Bear_Mat_Toys_0.geometry}
       material={materials.Mat_Toys} 
      // material={plasticMaterialBear}
       />
      {/* <mesh depthWrite={false} receiveShadow castShadow geometry={nodes.Cookie_Mat_Toys_0.geometry} material={materials.Mat_Toys} /> */}
      {/* <mesh depthWrite={false} receiveShadow castShadow geometry={nodes.Cookie3_Mat_Toys_0.geometry} material={materials.Mat_Toys} /> */}
    </group>
  )
}

useGLTF.preload('/models/toy_box-transformed.glb')
