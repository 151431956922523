/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 -i hand-cartoon.glb --transform --simplify
*/

import React, { useEffect, useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import gsap from 'gsap';
import { useThree } from '@react-three/fiber';
export function HandCartoon(props) {
  const { nodes, materials } = useGLTF('/models/hand-cartoon-transformed.glb')
  const {scene } = useThree()
  const refMesh = useRef()
  // positionHandX: { value: -48, min: -100, max: 0, label: "Hand X" },
  // positionHandY: { value: 12, min: 0, max: 40, label: "Hand Y" },
  // positionHandZ: { value: 8.3, min: -10, max: 20, label: "Hand Z" },
  /**
   * 
   * Empieza en 5.3
   * Termina en 9.5
   * 
   */
  useEffect(() => {
    if (props.finishAnimation){
setTimeout(() => {
  refMesh.current.visible = true;
  gsap.to(refMesh.current.position, {
    duration: 2,
    z:9.5,
    repeat: -1,
    yoyo: true,
    ease: "power1.inOut",
  });

}, 1800)

    }
  }, [props.finishAnimation]);

  useEffect(() => {
    if (props.removeHand){
      refMesh.current.traverse((child) => {
        if (child.isMesh) {
          child.geometry.dispose();
          child.material.dispose();
        }
      }
      );
      refMesh.current.visible = false;
      scene.remove(refMesh.current);
    }
  }, [props.removeHand]);

 
  return (
    <group ref={refMesh} {...props} dispose={null}   visible={false}
     position={[props.controlsScene.positionHandX, props.controlsScene.positionHandY, props.controlsScene.positionHandZ]} 
    scale={[0.0085,0.0085,0.0085]}
    rotation={[0,Math.PI * 0.5, 0]}>
      <mesh geometry={nodes.Plane001.geometry} material={nodes.Plane001.material}
       position={[0.04, 0.08, -0.01]} rotation={[Math.PI / 2, 0, 0]} >
      <meshMatcapMaterial color={props.controlsScene.colorHand} 
      />
      </mesh>
    </group>
  )
}

useGLTF.preload('/models/hand-cartoon-transformed.glb')
