/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 -i light2.glb --transform --simplify
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function LightFinal(props) {
  const { nodes, materials } = useGLTF('/models/light2-transformed.glb')
  return (
    <group {...props} dispose={null}    rotation={[0, Math.PI * 1.5, 0]}
    scale={0.4}
    position={[-70, 10, -13]}>
      <mesh geometry={nodes.Lightbeam2_Mat_Light_0.geometry} material={materials.Mat_Light} />
    </group>
  )
}

useGLTF.preload('/models/light2-transformed.glb')
