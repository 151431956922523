/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 -i soeme2.glb --transform --simplify
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function Soeme3(props) {
  const { nodes, materials } = useGLTF('/models/soeme2-transformed.glb')
  return (
    <group {...props} dispose={null}   
      scale={[4.6, 4.6, 4.6]}
    // position={[-50, 19, 11.34]}
    position={[
      props.controlsScene.positionSoemeX,
      props.controlsScene.positionSoemeY,
      props.controlsScene.positionSoemeZ,
    ]}
    rotation={[0, Math.PI * 0.5, 0]}
    >
      <mesh castShadow receiveShadow geometry={nodes.Soeme.geometry} material={nodes.Soeme.material} rotation={[Math.PI / 2, 0, 0]} >
      <meshStandardMaterial color="#ce0000" />
      </mesh>
    </group>
  )
}

useGLTF.preload('/models/soeme2-transformed.glb')
