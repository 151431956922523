import { useProgress } from "@react-three/drei";
import { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";

const LoadingDiv = ({ setLoadingComplete }) => {
  const totalProgress = 54;
  const [progress, setProgress] = useState();
  const { loaded, total } = useProgress();
  const refDiv = useRef(null);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // useEffect(() => {
  //   console.log("total", total);
  // }, [total]);
  useEffect(() => {
    // console.log("loaded", loaded);
    // console.log("total", total);
    const temp1 = (loaded / totalProgress) * 100;
    setProgress(parseInt(temp1));
  }, [loaded]);
  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100%",
          background: "#1e1e1e",
          zIndex: "100",
          position: "fixed",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        ref={refDiv}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {loaded === totalProgress ? (
            <button
              className="button-85"
              onClick={() => {
                refDiv.current.style.display = "none";

                setLoadingComplete(true);
              }}
            >
              START
            </button>
          ) : (
            <span
              className="font-1"
              style={{ fontSize: "34px", color: "#FFFFFF" }}
            >
              {progress}%
            </span>
          )}
          <div
            style={{
              position: "absolute",
              bottom: "0",
              left: "50%",
              transform: "translateX(-50%)",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              padding: "10px",
            }}
          >
            <Button
              variant="outline-dark"
              style={{
                // background: "#cfcfcf",
                borderColor: "#FFFFFF",
                color: "#FFFFFF",
              }}
              onClick={handleShow}
            >
              Credits
            </Button>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <h5>Credits:</h5>
          <p>
            <b>Room:</b>{" "}
            https://sketchfab.com/3d-models/toy-box-8ce01f03c4c54c1bb69f6ba47a590586
          </p>
          <p>
            <b>Hand:</b>{" "}
            https://www.cgtrader.com/free-3d-models/character/anatomy/funny-cartoon-hands
          </p>
          <p>
            <b>Textures:</b> https://polyhaven.com/
          </p>

          <p>
            <b>Sound effects:</b> https://elevenlabs.io/
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LoadingDiv;
