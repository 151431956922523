import { useEffect, useRef, useState } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import * as THREE from "three";
import gsap from "gsap";
import { isMobile } from "react-device-detect";

const CamaraCharacter = ({
  refCharacter,
  firstCamera,
  secondCamera,
  refCamera,
  thirdCamera,
  setFinishFirstCamera,
}) => {
  const deltaTop = 0.2;
  // const initialPosition = useRef(new THREE.Vector3(-24, 17.5, 0));
  const [finishSecond, setFinishSecond] = useState(false);

  useEffect(() => {
    if (firstCamera && !secondCamera && !thirdCamera) {
      const cameraOffset = new THREE.Vector3(7.5, 1, 0);
      const position = refCharacter.current.position;
      const targetPosition = new THREE.Vector3()
        .copy(position)
        .add(cameraOffset);

      if (isMobile) {
        gsap.to(refCamera.current.position, {
          duration: 5.6, // Duración de la animación en segundos
          x: targetPosition.x,
          y: targetPosition.y,
          z: targetPosition.z,
          onComplete: () => {
            setFinishFirstCamera(true);
          },
        });
      } else {
        gsap.to(refCamera.current.position, {
          duration: 3, // Duración de la animación en segundos
          x: targetPosition.x,
          y: targetPosition.y,
          z: targetPosition.z,
          onComplete: () => {
            setFinishFirstCamera(true);
          },
        });
      }
    }
  }, [firstCamera]);

  useEffect(() => {
    if (
      !firstCamera &&
      !secondCamera &&
      thirdCamera &&
      !isMobile
      // &&
      // finishSecond
    ) {
      gsap.to(refCamera.current.position, {
        x: -27,
        y: 15,
        z: 0,
        duration: 2.5,
        ease: "power2.inOut",
        onComplete: () => {
          // //  {x: -0.9966612665151221, y: 0.04709822112897272, z: -0.06669390822975618}
          // const vector = new THREE.Vector3(
          //   -0.9966612665151221,
          //   0.04709822112897272,
          //   -0.06669390822975618
          // );
          // refCamera.current.lookAt(vector);
        },
      });
      // {x: -41.070618392319574, y: 21.437520103438043, z: 7.703372336109862}
      // gsap.to(refCamera.current.lookAt, {
      //   x: -41.070618392319574,
      //   y: 21.437520103438043,
      //   z: 7.703372336109862,
      //   duration: 0.5,
      //   ease: "power2.inOut",
      // });

      // gsap.to(refCamera.current.rotation, {
      //   x: 0.6148540188443008,
      //   y: 1.4890565670664857,
      //   z: -0.6132790262632453,
      //   duration: 0.5,
      //   ease: "power2.inOut",
      // });
    }
  }, [thirdCamera]);

  //   useFrame(() => {
  //     if (secondCamera) {
  //       const cameraOffset = new THREE.Vector3(10, -1, 0);
  //       const position = refCharacter.current.position;
  //       const targetPosition = new THREE.Vector3()
  //         .copy(position)
  //         .add(cameraOffset);

  //       // Interpolar la posición de la cámara
  //       refCamera.current.position.lerp(targetPosition, 0.01); // 0.1 es la cantidad de interpolación, puedes ajustarla según tus necesidades
  //       refCamera.current.lookAt(position.x, position.y - 0.5, position.z);
  //     }
  //   });
  // useFrame((state, delta) => {
  //   if (delta > deltaTop) return;
  //   if (secondCamera) {
  //     const cameraOffset = new THREE.Vector3(10, -1, 0);
  //     const position = refCharacter.current.position;
  //     const targetPosition = new THREE.Vector3()
  //       .copy(position)
  //       .add(cameraOffset);

  //     // Interpolar la posición de la cámara
  //     refCamera.current.position.lerp(targetPosition, 0.01); // 0.01 es la cantidad de interpolación, puedes ajustarla según tus necesidades

  //     // Interpolar la dirección de la cámara
  //     const lookAtTarget = new THREE.Vector3(
  //       position.x,
  //       position.y - 0.5,
  //       position.z
  //     );
  //     const currentLookAt = new THREE.Vector3();
  //     refCamera.current.getWorldDirection(currentLookAt);
  //     const newLookAt = new THREE.Vector3().lerpVectors(
  //       currentLookAt.add(refCamera.current.position),
  //       lookAtTarget,
  //       0.005
  //     ); // 0.01 es la cantidad de interpolación, puedes ajustarla según tus necesidades

  //     refCamera.current.lookAt(newLookAt);
  //   }
  // });
  useFrame((state, delta) => {
    if (delta > deltaTop) return;
    if (secondCamera) {
      // const cameraOffset = new THREE.Vector3(10, -1, 0);
      const cameraOffset = new THREE.Vector3(10, -1, 0);
      const position = refCharacter.current.position;
      const targetPosition = new THREE.Vector3()
        .copy(position)
        .add(cameraOffset);

      // Interpolar la posición de la cámara con delta
      refCamera.current.position.lerp(targetPosition, delta * 0.625);

      // Interpolar la dirección de la cámara con delta
      const lookAtTarget = new THREE.Vector3(
        position.x,
        position.y - 0.5,
        position.z
      );
      const currentLookAt = new THREE.Vector3();
      refCamera.current.getWorldDirection(currentLookAt);
      const newLookAt = new THREE.Vector3().lerpVectors(
        currentLookAt.add(refCamera.current.position),
        lookAtTarget,
        // 0.005 * delta
        0.005
      );

      refCamera.current.lookAt(newLookAt);
    }
  });
  // useFrame(() => {
  //   if (secondCamera && !finishSecond) {
  //     const cameraOffset = new THREE.Vector3(10, -1, 0);
  //     const position = refCharacter.current.position;
  //     const targetPosition = new THREE.Vector3()
  //       .copy(position)
  //       .add(cameraOffset);

  //     // Interpolar la posición de la cámara
  //     refCamera.current.position.lerp(targetPosition, 0.01); // 0.01 es la cantidad de interpolación, puedes ajustarla según tus necesidades

  //     // Interpolar la dirección de la cámara
  //     const lookAtTarget = new THREE.Vector3(
  //       position.x,
  //       position.y - 0.5,
  //       position.z
  //     );
  //     const currentLookAt = new THREE.Vector3();
  //     refCamera.current.getWorldDirection(currentLookAt);
  //     const newLookAt = new THREE.Vector3().lerpVectors(
  //       currentLookAt.add(refCamera.current.position),
  //       lookAtTarget,
  //       0.005
  //     ); // 0.005 es la cantidad de interpolación, puedes ajustarla según tus necesidades

  //     refCamera.current.lookAt(newLookAt);

  //     // Verificar si la cámara ha alcanzado la posición objetivo
  //     if (
  //       refCamera.current.position.distanceTo(targetPosition) < 0.2 &&
  //       refCamera.current
  //         .getWorldDirection(new THREE.Vector3())
  //         .distanceTo(
  //           lookAtTarget.sub(refCamera.current.position).normalize()
  //         ) < 0.1
  //     ) {
  //       setFinishSecond(true);
  //     }
  //   }
  // });
  return null;
};

export default CamaraCharacter;
