/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 -i contact2.glb --transform
*/

import React, { useEffect, useRef, useState } from 'react'
import { Float, useGLTF } from '@react-three/drei'
import * as THREE from 'three'
import { isMobile } from 'react-device-detect';

export function ButtonContact(props) {
  const [hover, setHover] = useState(false);
  const [hover2, setHover2] = useState(false);
  const [changeMaterial, setChangeMaterial] = useState(false);
  const { nodes, materials } = useGLTF('/models/contact2-transformed.glb')
const color1Original = "#0525B4";
const color2Original = "#E70200";

  useEffect(() => {
 if (hover || hover2) {
      setChangeMaterial(true);
      document.body.style.cursor = 'pointer';
    } else {
      setChangeMaterial(false);
      document.body.style.cursor = 'default';

    }
    
  }, [hover, hover2]);

  useEffect(() => {
    if (changeMaterial){
      materials['Material.004'].color = new THREE.Color("#4F00A7");
      materials['Material.006'].color = new THREE.Color("#BB70FF");

    } else{
      materials['Material.004'].color= new THREE.Color(color1Original);
      materials['Material.006'].color = new THREE.Color(color2Original);
    }
  }, [changeMaterial]);
  const handleOpen = () => {
    window.open("https://www.linkedin.com/in/gerardo-duckwitz", "_blank");
  }

  // x: -16,
  //       y: 16,
  //       z: 7.37,
  // (-50, 18.65, 7.36);
if (isMobile){
  return (
    <Float
    speed={4} // Animation speed, defaults to 1
    rotationIntensity={0.5

    } // XYZ rotation intensity, defaults to 1
    floatIntensity={0.5} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
    floatingRange={[-0.1,0.1]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
  >
      <group {...props} dispose={null}  
      rotation={[0, 0,-Math.PI * 0.05]}
        scale={[1.4, 1.4, 1.4]}
   position={[
    -50,
    27.5,
    7.36
   ]}
   onClick={handleOpen}
     >
        <mesh  geometry={nodes.Cube001.geometry} material={materials['Material.004']} scale={0.84}  onPointerEnter={() => setHover(true)} onPointerLeave={() => setHover(false)}/>
        <mesh geometry={nodes.Text001.geometry} material={materials['Material.006']} position={[0.38, 0.53, 0.57]} onPointerEnter={() => setHover2(true)}  onPointerLeave={() => setHover2(false)}/>
      </group>
  </Float>
  )
}
  return (
    <Float
  speed={4} // Animation speed, defaults to 1
  rotationIntensity={0.5} // XYZ rotation intensity, defaults to 1
  floatIntensity={1} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
  floatingRange={[0.5,1]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
>
    <group {...props} dispose={null}    scale={[1.4, 1.4, 1.4]}
 position={[
  props.controlsScene.contactX,
  props.controlsScene.contactY,
  props.controlsScene.contactZ,
 ]}
    rotation={[0, -Math.PI * 0.2, 0]}
    onClick={handleOpen}
   >
      <mesh  geometry={nodes.Cube001.geometry} material={materials['Material.004']} scale={0.84}  onPointerEnter={() => setHover(true)} onPointerLeave={() => setHover(false)}/>
      <mesh  geometry={nodes.Text001.geometry} material={materials['Material.006']} position={[0.38, 0.53, 0.57]} onPointerEnter={() => setHover2(true)}  onPointerLeave={() => setHover2(false)}/>
    </group>
</Float>
  )
}

useGLTF.preload('/models/contact2-transformed.glb')
