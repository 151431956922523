import { useEffect, useRef, useState } from "react";
import CamaraCharacter from "./Animations/CameraCharacter";
import CharacterAnimation from "./CharacterAnimations";
import { useFrame, useThree } from "@react-three/fiber";
import { useGLTF } from "@react-three/drei";
import * as THREE from "three";

const Character = ({
  controlsScene,
  setStartThirdAnimation,
  refCamera,
  startSecondAnimation,
  setStartUltimateAnimation,
  setLowSoundAmbient,
  activateSound,
  finishAnimationsCamera,
  setFinishAnimation,
}) => {
  const velocityAnimation = 0.6;

  const glb = useGLTF("/animations/model_happy.gltf");
  const [firstCamera, setFirstCamera] = useState(false);
  const [secondCamera, setSecondCamera] = useState(false);
  const [thirdCamera, setThirdCamera] = useState(false);
  const [initCharacterAnimation, setInitCharacterAnimation] = useState(false);
  const [finishFirstCamera, setFinishFirstCamera] = useState();
  const refCharacter = useRef();
  const refMixer = useRef(new THREE.AnimationMixer(glb.scene));
  const refActionIdle = useRef();

  useEffect(() => {
    glb.scene.traverse((child) => {
      if (child.isMesh) {
        child.castShadow = true;
        child.receiveShadow = true;
      }
    });
  }, []);

  useEffect(() => {
    if (startSecondAnimation) {
      setInitCharacterAnimation(true);
      setFirstCamera(true);
    }
  }, [startSecondAnimation]);

  useEffect(() => {
    if (firstCamera || secondCamera || thirdCamera) {
      setLowSoundAmbient(true);
    } else {
      setLowSoundAmbient(false);
    }
  }, [firstCamera]);

  useEffect(() => {
    const animationIdleClip = glb.animations.find(
      (clip) => clip.name === "Armature|mixamo.com|Layer0.001"
    );
    refActionIdle.current = refMixer.current.clipAction(animationIdleClip);
    refActionIdle.current.clampWhenFinished = true;
  }, []);
  useFrame((state, delta) => {
    if (refMixer.current) {
      refMixer.current.update(delta * velocityAnimation);
    }
  });
  useEffect(() => {
    if (finishAnimationsCamera) {
      refActionIdle.current.fadeIn(1.0).play();
    }
  }, [finishAnimationsCamera]);

  return (
    <>
      <primitive
        object={glb.scene}
        position={[
          controlsScene.positionCharacterX,
          controlsScene.positionCharacterY,
          controlsScene.positionCharacterZ,
        ]}
        rotation={[0, Math.PI * 0.5, 0]}
        ref={refCharacter}
      />
      <CamaraCharacter
        refCharacter={refCharacter}
        refCamera={refCamera}
        firstCamera={firstCamera}
        secondCamera={secondCamera}
        thirdCamera={thirdCamera}
        setFinishFirstCamera={setFinishFirstCamera}
      />
      {!finishAnimationsCamera && (
        <CharacterAnimation
          glb={glb}
          setStartThirdAnimation={setStartThirdAnimation}
          refCamera={refCamera}
          refCharacter={refCharacter}
          setFirstCamera={setFirstCamera}
          setSecondCamera={setSecondCamera}
          setThirdCamera={setThirdCamera}
          controlsScene={controlsScene}
          initCharacterAnimation={initCharacterAnimation}
          setStartUltimateAnimation={setStartUltimateAnimation}
          activateSound={activateSound}
          setFinishAnimation={setFinishAnimation}
          finishFirstCamera={finishFirstCamera}
        />
      )}
    </>
  );
};

export default Character;
