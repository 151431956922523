// import { useHelper } from "@react-three/drei";
// import { useRef } from "react";
// import { DirectionalLightHelper } from "three";
// import * as THREE from "three";

// const Lights = ({ controlsScene }) => {
//   const refLight = useRef();
//   const refObjectTarget = useRef(new THREE.Object3D());
//   useHelper(refLight, DirectionalLightHelper, 10);

//   refObjectTarget.current.position.set(
//     controlsScene.targetX,
//     controlsScene.targetY,
//     controlsScene.targetZ
//   );

//   return (
//     <>
//       <directionalLight
//         // color="#FFD700"
//         color={controlsScene.color}
//         castShadow
//         shadow-mapSize={[1024, 1024]}
//         // shadow-camera-far={80}
//         // shadow-camera-left={-200}
//         // shadow-camera-right={40}
//         // shadow-camera-top={140}
//         // shadow-camera-bottom={-150}
//         shadow-camera-far={150}
//         shadow-camera-left={-190}
//         shadow-camera-right={50}
//         shadow-camera-top={80}
//         shadow-camera-bottom={-40}
//         intensity={controlsScene.intensityLight}
//         position={[
//           controlsScene.directionalPositionX,
//           controlsScene.directionalPositionY,
//           controlsScene.directionalPositionZ,
//         ]}
//         ref={refLight}
//         target={refObjectTarget.current}
//       />
//     </>
//   );
// };

// export default Lights;
import { useHelper } from "@react-three/drei";
import { useRef, useEffect } from "react";
import { DirectionalLightHelper } from "three";
import * as THREE from "three";

const Lights = ({ controlsScene, showHelper = false }) => {
  const refLight = useRef();
  const refObjectTarget = useRef(new THREE.Object3D());

  // Usar useEffect para actualizar la posición del target
  useEffect(() => {
    refObjectTarget.current.position.set(
      controlsScene.targetX,
      controlsScene.targetY,
      controlsScene.targetZ
    );
    refLight.current.target = refObjectTarget.current;
  }, [controlsScene.targetX, controlsScene.targetY, controlsScene.targetZ]);

  // Usar useHelper solo si showHelper es verdadero
  useHelper(showHelper ? refLight : null, DirectionalLightHelper, 10);

  return (
    <>
      <directionalLight
        color={controlsScene.color}
        castShadow
        shadow-mapSize={[2048, 2048]}
        shadow-camera-far={150}
        shadow-camera-left={-190}
        shadow-camera-right={50}
        shadow-camera-top={80}
        shadow-camera-bottom={-40}
        intensity={controlsScene.intensityLight}
        position={[
          controlsScene.directionalPositionX,
          controlsScene.directionalPositionY,
          controlsScene.directionalPositionZ,
        ]}
        ref={refLight}
      />
      {/* Asegurarse de que el target sea un hijo del scene para ser parte del árbol de objetos */}
      <primitive object={refObjectTarget.current} />
    </>
  );
};

export default Lights;
