/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 -i window-wall3.glb --transform --simplify
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import * as THREE from 'three'

export function WindowWall(props) {
  const { nodes, materials } = useGLTF('/models/window-wall3-transformed.glb')

  // const materialWall = new THREE.MeshMatcapMaterial({
  //   color: props.controlsScene.colorWall,
  //   side: THREE.DoubleSide,

  // });
  const materialWindow = new THREE.MeshMatcapMaterial({
    color:'#FFFFFF',
    side: THREE.DoubleSide,
  });
  return (
    <group {...props} dispose={null}  rotation={[0, Math.PI * 1.5, 0]}
    scale={0.4}
    position={[-70, 10, -13]}>
      <mesh castShadow receiveShadow geometry={nodes.Wall.geometry}
      //  material={nodes.Wall.material}
        material={props.materialWall}
        />
      <mesh castShadow receiveShadow geometry={nodes.Window.geometry}
      //  material={nodes.Window.material}
        material={materialWindow}
       />
    </group>
  )
}

useGLTF.preload('/models/window-wall3-transformed.glb')
