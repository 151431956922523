import { useThree } from "@react-three/fiber";
import { useEffect, useRef } from "react";
import gsap from "gsap";
import { isMobile } from "react-device-detect";

const CamaraAnimation = ({
  refCamera,
  setStartSecondAnimation,
  startCameraAnimation,
  setFinishAnimation,
}) => {
  const refInit = useRef(false);
  useEffect(() => {
    if (refInit.current) return;
    if (!startCameraAnimation) return;
    refInit.current = true;
    // cameraX: { value: -30, min: -40, max: 0, label: "Camera X" },
    // cameraY: { value: 20.5, min: 0, max: 30, label: "Camera Y" },
    // cameraZ: { value: 6, min: 0, max: 15, label: "Camera Z" },
    if (isMobile) {
      setStartSecondAnimation(true);
      return;
    }
    gsap.to(refCamera.current.position, {
      // x: -24,
      // y: 17.5,
      // z: 0,
      // duration: 0.5,
      x: -30,
      y: 20.5,
      z: 6,
      duration: 4.6,
      ease: "power1.inOut",
      onComplete: () => {
        setStartSecondAnimation(true);
      },
    });
    // if (isMobile) {
    //   gsap.to(refCamera.current.position, {
    //     // x: -24,
    //     // y: 17.5,
    //     // z: 1.73,
    //     x: -16,
    //     y: 16,
    //     z: 7.37,
    //     duration: 5.8,
    //     ease: "power1.inOut",
    //     onComplete: () => {
    //       // setStartSecondAnimation(true);
    //       setFinishAnimation(true);
    //     },
    //   });
    // } else {
    //   gsap.to(refCamera.current.position, {
    //     // x: -24,
    //     // y: 17.5,
    //     // z: 0,
    //     // duration: 0.5,
    //     x: -30,
    //     y: 20.5,
    //     z: 6,
    //     duration: 4.6,
    //     ease: "power1.inOut",
    //     onComplete: () => {
    //       setStartSecondAnimation(true);
    //     },
    //   });
    // }
  }, [startCameraAnimation]);
  return null;
};

export default CamaraAnimation;
