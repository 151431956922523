import { useFrame } from "@react-three/fiber";
import { easing } from "maath";
import { useRef, useEffect } from "react";
import * as THREE from "three";

const CameraRig = ({ refCamera }) => {
  const initialPosition = useRef(new THREE.Vector3(-24, 15, 2));
  // x: -24,
  // y: 17.5,
  // z: 0,
  useEffect(() => {
    if (refCamera.current) {
      refCamera.current.position.set(-24, 15, 2);
    }
  }, [refCamera]);

  useFrame((state, delta) => {
    if (!refCamera.current) return;

    // Calcular el movimiento basado en la posición del puntero
    const offsetX = (state.pointer.x * state.viewport.width) / 20;
    const offsetY = (state.pointer.y * state.viewport.height) / 20;

    // Limitar el movimiento a un rango de -1 a 1
    const limitedOffsetX = Math.max(-1, Math.min(1.6, offsetX));
    const limitedOffsetY = Math.max(-1, Math.min(1.6, offsetY));

    // Ajustar la posición de la cámara basado en la posición inicial y el movimiento limitado
    const targetPosition = initialPosition.current.clone();
    targetPosition.x += limitedOffsetX;
    targetPosition.y += limitedOffsetY;

    easing.damp3(refCamera.current.position, targetPosition, 0.5, delta);
  });

  return null;
};

export default CameraRig;
