import { OrbitControls, PerspectiveCamera, Stats } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import Glass from "./Glass";
import CamaraAnimation from "./Animations/CameraAnimation";
import { useEffect, useRef, useState } from "react";
import Init from "./classes/InitThreeScene";
import AmbientSound from "./sounds/AmbientSound";
import Room from "./Room/Room";
import * as THREE from "three";
import CameraSecondAnimation from "./Animations/CameraSecondAnimation";
import CameraRig from "./utils/CameraRig";
import { isMobile } from "react-device-detect";
import { Leva } from "leva";
import gsap from "gsap";

const Experience = ({ refHand, loadingComplete, activateSound }) => {
  // const [startLetterAnimation, setStartLetterAnimation] = useState(false);
  const [startCameraAnimation, setStartCameraAnimation] = useState(false);
  const [startSecondAnimation, setStartSecondAnimation] = useState(false);
  const [startThirdAnimation, setStartThirdAnimation] = useState(false);
  const [finishAnimation, setFinishAnimation] = useState(false);
  const [hideHand, setHideHand] = useState(true);
  const [removeHand, setRemoveHand] = useState(false);
  const [glassFinished, setGlassFinished] = useState(false);
  const [removeGlass, setRemoveGlass] = useState(false);
  const [deleteGlass, setDeleteGlass] = useState(false);
  const [startAmbientSound, setStartAmbientSound] = useState(false);
  const [startUltimateAnimation, setStartUltimateAnimation] = useState(false);
  const [lowSoundAmbient, setLowSoundAmbient] = useState(false);
  const [finishAnimationsCamera, setFinishAnimationsCamera] = useState(false);
  const refCamera = useRef();
  useEffect(() => {
    if (!glassFinished) return;
    setStartCameraAnimation(true);
    setTimeout(() => {
      setStartAmbientSound(true);
    }, 300);
    setTimeout(() => {
      setRemoveGlass(true);
      setTimeout(() => {
        setDeleteGlass(true);
      }, 1000);
    }, 3300);
  }, [glassFinished]);
  const receiveCamera = (camera) => {
    refCamera.current = camera;
  };

  useEffect(() => {
    if (!finishAnimationsCamera) return;
    // gsap.to(refCamera.current.lookAt, {
    //   x: -41.070618392319574,
    //   y: 21.437520103438043,
    //   z: 7.703372336109862,
    //   duration: 1.5,
    //   ease: "power2.inOut",
    // });

    // gsap.to(refCamera.current.rotation, {
    //   x: 0.6148540188443008,
    //   y: 1.4890565670664857,
    //   z: -0.6132790262632453,
    //   duration: 1.5,
    //   ease: "power2.inOut",
    // });
  }, [finishAnimationsCamera]);
  return (
    <Canvas
      dpr={[1, 3]}
      gl={{
        powerPreference: "high-performance",
      }}
      shadows
    >
      <Leva hidden />
      {/* <Stats /> */}
      {/* <color attach="background" args={["#CCCCCC"]} /> */}
      {/* <OrbitControls target={[-50, 15, 0]} /> */}

      <ambientLight intensity={1.5} />
      {/* Aca empieza el proyecto */}
      {/* El glass ocupa rendimiento */}
      {!deleteGlass && (
        <Glass
          loadingComplete={loadingComplete}
          setGlassFinished={setGlassFinished}
          glassFinished={glassFinished}
          removeGlass={removeGlass}
          activateSound={activateSound}
        />
      )}
      <CamaraAnimation
        refCamera={refCamera}
        setStartSecondAnimation={setStartSecondAnimation}
        startCameraAnimation={startCameraAnimation}
        setFinishAnimation={setFinishAnimation}
      />
      <CameraSecondAnimation
        // startSecondAnimation={startSecondAnimation}
        refCamera={refCamera}
        // setStartThirdAnimation={setStartThirdAnimation}
        startUltimateAnimation={startUltimateAnimation}
        setFinishAnimationsCamera={setFinishAnimationsCamera}
      />
      <Room
        receiveCamera={receiveCamera}
        finishAnimation={finishAnimation}
        hideHand={hideHand}
        removeHand={removeHand}
        setStartThirdAnimation={setStartThirdAnimation}
        startSecondAnimation={startSecondAnimation}
        setStartUltimateAnimation={setStartUltimateAnimation}
        setLowSoundAmbient={setLowSoundAmbient}
        activateSound={activateSound}
        finishAnimationsCamera={finishAnimationsCamera}
        setFinishAnimation={setFinishAnimation}
      />
      <Init
        refCamera={refCamera}
        startThirdAnimation={startThirdAnimation}
        refHand={refHand}
        activateSound={activateSound}
        setStartThirdAnimation={setStartThirdAnimation}
        setFinishAnimation={setFinishAnimation}
        setHideHand={setHideHand}
        finishAnimation={finishAnimation}
        setRemoveHand={setRemoveHand}
      />
      <AmbientSound
        startAmbientSound={startAmbientSound}
        activateSound={activateSound}
        startThirdAnimation={startThirdAnimation}
        lowSoundAmbient={lowSoundAmbient}
      />

      {finishAnimation && finishAnimationsCamera && !isMobile && (
        <CameraRig refCamera={refCamera} />
      )}
    </Canvas>
  );
};

export default Experience;
