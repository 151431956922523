import { useThree } from "@react-three/fiber";
import { useEffect, useState } from "react";
import * as THREE from "three";

const AmbientSound = ({
  startAmbientSound,
  activateSound,
  startThirdAnimation,
  lowSoundAmbient,
}) => {
  const { camera } = useThree();
  const [finishAmbienMusicVolume, setFinishAmbienMusicVolume] = useState(false);
  const [listenerSoundAmbient] = useState(() => new THREE.AudioListener());
  const [soundAmbient] = useState(() => new THREE.Audio(listenerSoundAmbient));
  const [listenerSoundAmbient2] = useState(() => new THREE.AudioListener());
  const [soundAmbient2] = useState(
    () => new THREE.Audio(listenerSoundAmbient2)
  );
  const [activateSecond, setActivateSecond] = useState();

  useEffect(() => {
    /**
     *
     * Sonido Ambiente
     *
     */
    const audioLoader = new THREE.AudioLoader();
    audioLoader.load("./sounds/ambiente3.mp3", function (buffer) {
      camera.add(listenerSoundAmbient);
      soundAmbient.setBuffer(buffer);
      soundAmbient.setLoop(true);
      soundAmbient.setVolume(0.43);
      // soundAmbient.play();
    });
  }, []);

  useEffect(() => {
    if (activateSound && finishAmbienMusicVolume) {
      soundAmbient.setVolume(0.13);
    } else if (activateSound && !finishAmbienMusicVolume) {
      soundAmbient.setVolume(0.43);
    } else {
      soundAmbient.setVolume(0);
    }
  }, [activateSound]);

  // useEffect(() => {
  //   // Esta third empieza con cuando tiene que bajar la musica
  //   if (startThirdAnimation) {
  //     soundAmbient.setVolume(0.01);
  //   } else {
  //     soundAmbient.setVolume(0.43);
  //   }
  // }, [startThirdAnimation]);

  useEffect(() => {
    if (!startAmbientSound) return;
    soundAmbient.play();
  }, [startAmbientSound]);

  useEffect(() => {
    if (lowSoundAmbient && activateSound) {
      setFinishAmbienMusicVolume(true);
      let currentVolume = soundAmbient.getVolume();
      const targetVolume = 0.13;
      const duration = 2000; // Duración de la interpolación en milisegundos
      const steps = 20; // Número de pasos en la interpolación
      const stepDuration = duration / steps;
      const volumeStep = (currentVolume - targetVolume) / steps;
      if (soundAmbient.getVolume() < 0.14) return;
      const interval = setInterval(() => {
        currentVolume -= volumeStep;
        if (currentVolume <= targetVolume) {
          currentVolume = targetVolume;
          clearInterval(interval);
          return;
        }
        soundAmbient.setVolume(currentVolume);
      }, stepDuration);
    } else if (activateSound) {
      // soundAmbient.setVolume(0.23);
    }
  }, [lowSoundAmbient, activateSound]);

  return null;
};

export default AmbientSound;
