import {
  Environment,
  OrbitControls,
  PerspectiveCamera,
  SoftShadows,
  useTexture,
} from "@react-three/drei";
import { useControls, Leva } from "leva";
import { Model } from "./ToyBox";
import Lights from "./Lights";
import { Bloom, EffectComposer } from "@react-three/postprocessing";
import { ButtonContact } from "./Contact2";
import { useEffect, useRef, useState } from "react";
import { WindowWall } from "./WindowWall";
import * as THREE from "three";
import { HandCartoon } from "./HandCartoon";
import Character from "../Character";
import { useThree } from "@react-three/fiber";
import { Soeme3 } from "./Soeme";
import { LightFinal } from "./LightFinal";

const Room = ({
  receiveCamera,
  finishAnimation,
  hideHand,
  setStartThirdAnimation,
  startSecondAnimation,
  setStartUltimateAnimation,
  setLowSoundAmbient,
  activateSound,
  finishAnimationsCamera,
  setFinishAnimation,
  removeHand,
}) => {
  const textureWall = useTexture("/textures/wood/synthetic_wood_diff_1k.jpg");
  textureWall.wrapS = THREE.RepeatWrapping;
  textureWall.wrapT = THREE.RepeatWrapping;
  textureWall.repeat.set(10, 10);
  const materialWall = new THREE.MeshMatcapMaterial({
    // color: props.controlsScene.colorWall,
    side: THREE.DoubleSide,
    map: textureWall,
  });
  const refCamera = useRef();
  const controlsScene = useControls({
    positionX: { value: 40, min: 0.0, max: 40.0, label: "Box X" },
    positionY: { value: 20.4, min: -20.0, max: 60.0, label: "Box Y" },
    positionZ: { value: -50, min: -50.0, max: 10.0, label: "Box Z" },

    luminanceThreshold: {
      value: 0,
      min: 0.0,
      max: 3.0,
      label: "Luminance Threshold",
    },
    mipmapBlur: { value: true, label: "Mipmap Blur" },
    radius: { value: 0.1, min: 0.0, max: 1, label: "Radius" },
    intensity: { value: -1, min: 0.0, max: 3.0, label: "Intensity" },
    directionalPositionX: {
      value: -74,
      min: -100,
      max: 20,
      label: "Light X",
    },
    directionalPositionY: {
      // value: 112,
      // value: 58,
      value: 64,
      min: 0,
      max: 140,
      label: "Light Y",
    },
    directionalPositionZ: {
      // value: -20.0,
      value: -65,
      min: -150,
      max: 50,
      label: "Light Z",
    },
    // (20, 30, -35);
    targetX: { value: -4, min: -100, max: 100, label: "Target X" },
    targetY: { value: -235, min: -300, max: 40, label: "Target Y" },
    targetZ: { value: 295, min: -10, max: 500, label: "Target Z" },
    near: { value: 0.5, min: 0, max: 4, label: "Near" },
    far: { value: 530, min: 0, max: 1000, label: "Far" },
    n8I: { value: 1, min: 0, max: 3, label: "N8AOIntensity" },
    n8a: { value: 1.22, min: 0, max: 3, label: "N8AORadius" },
    intensityLight: { value: 29, min: 0, max: 35, label: "LightIntensity" },
    camaraX: { value: 40, min: -100, max: 500, label: "Camara X" },
    camaraY: { value: 78, min: -100, max: 500, label: "Camara Y" },
    camaraZ: { value: -230, min: -500, max: 500, label: "Camara Z" },
    orbitX: { value: 0, min: -100, max: 200, label: "Orbit X" },
    orbitY: { value: 45, min: -100, max: 200, label: "Orbit Y" },
    orbitZ: { value: 200, min: -100, max: 200, label: "Orbit Z" },
    color: "#FFD700",
    colorWall: "#abc0ff",
    roughnessWall: { value: 0.54, min: 0.0, max: 1.0, label: "Roughness" },
    metalnessWall: { value: 0, min: 0.0, max: 1.0, label: "Metalness" },
    envIntesity: { value: 0.5, min: 0.0, max: 10.0, label: "Env Intensity" },
    emissive: "#313ccb",
    emissiveIntensity: { value: 9.6, min: 0.0, max: 20.0, label: "Emissive" },
    // position={[-70, 30, -13]}
    windowX: { value: -60, min: -100, max: 100, label: "Window X" },
    windowY: { value: 40, min: -100, max: 100, label: "Window Y" },
    windowZ: { value: -39, min: -100, max: 100, label: "Window Z" },
    rotationWindowX: {
      value: 0.84,
      min: -3.14,
      max: 3.14,
      label: "Rotation X",
    },
    colorTecho: "#926bff",
    colorTechoEmisive: "#4502ff",
    colorTechoEmissiveIntensity: { value: 2, min: 0.0, max: 20.0 },
    emissiveFloor: "#000000",
    emissiveIntensityFloor: { value: 2, min: 0.0, max: 20.0 },
    roughnessFloor: { value: 1.0, min: 0.0, max: 1.0, label: "Roughness" },
    metalnessFloor: { value: 0.1, min: 0.0, max: 1.0, label: "Metalness" },
    colorFloor: "#D8E5FC",
    colorSoeme: "#ff0000",
    roughnessSoeme: { value: 0.62, min: 0.0, max: 1.0, label: "Roughness" },
    metalnessSoeme: { value: 1, min: 0.0, max: 1.0, label: "Metalness" },
    emissiveSoeme: "#ff0000",
    emissiveIntensitySoeme: { value: 4.6, min: 0.0, max: 20.0 },
    lightFar: { value: 200, min: 0, max: 500, label: "Light Far" },
    lightLeft: { value: -400, min: -500, max: 500, label: "Light Left" },
    lightRight: { value: 400, min: -500, max: 500, label: "Light Right" },
    lightTop: { value: 340, min: -500, max: 500, label: "Light Top" },
    lightBottom: { value: -350, min: -500, max: 500, label: "Light Bottom" },
    factorLight: { value: 1.5, min: 0.0, max: 3.0, label: "Factor Light" },
    emitX: { value: 0, min: -100, max: 100, label: "Emit X" },
    emitY: { value: 0, min: -300, max: 0, label: "Emit Y" },
    emitZ: { value: 0, min: -100, max: 100, label: "Emit Z" },
    // position={[-50, 19, 11.34]}
    positionSoemeX: { value: -50, min: -100, max: 100, label: "Soeme X" },
    positionSoemeY: { value: 19, min: -100, max: 100, label: "Soeme Y" },
    positionSoemeZ: { value: 17, min: 6, max: 26, label: "Soeme Z" },
    // position={[-44, 12, -17]}
    contactX: { value: -50, min: -60, max: 0, label: "Contact X" },
    contactY: { value: 12, min: -10, max: 20, label: "Contact Y" },
    contactZ: { value: -10.8, min: -24, max: 0, label: "Contact Z" },
    // position={[155, 20, 0]}
    cameraX: { value: 155, min: -100, max: 200, label: "Camera X" },
    cameraY: { value: 51, min: 0, max: 100, label: "Camera Y" },
    cameraZ: { value: 0, min: -50, max: 50, label: "Camera Z" },
    // Posicion para segunda animacion mostrando solo Soweme
    // position={[-30, 21, 6]}
    // cameraX: { value: -30, min: -40, max: 0, label: "Camera X" },
    // cameraY: { value: 20.5, min: 0, max: 30, label: "Camera Y" },
    // cameraZ: { value: 6, min: 0, max: 15, label: "Camera Z" },
    // position={[-70, 10, -13]}
    positionHandX: { value: -48, min: -100, max: 0, label: "Hand X" },
    positionHandY: { value: 12, min: 0, max: 40, label: "Hand Y" },
    positionHandZ: { value: 5.3, min: -10, max: 20, label: "Hand Z" },
    scaleHand: { value: 0.1, min: 0.0, max: 1.0, label: "Scale Hand" },
    colorHand: "#FFFFFF",
    positionCharacterX: {
      value: -50,
      min: -100,
      max: 100,
      label: "Character X",
    },
    positionCharacterY: {
      value: 22.365,
      min: 22,
      max: 23,
      label: "Character Y",
    },
    positionCharacterZ: { value: 16.0, min: 6, max: 26, label: "Character Z" },
  });

  return (
    <>
      <PerspectiveCamera
        makeDefault
        //Posicion original
        // position={[155, 20, 0]}
        // position={[-22, 20, 0]}
        position={[
          controlsScene.cameraX,
          controlsScene.cameraY,
          controlsScene.cameraZ,
        ]}
        rotation={[0, Math.PI * 0.5, 0]}
        ref={(ref) => {
          receiveCamera(ref);
          refCamera.current = ref;
        }}
        near={controlsScene.near}
        far={controlsScene.far}
      />
      <Model controlsScene={controlsScene} materialWall={materialWall} />

      <Lights controlsScene={controlsScene} />

      <Environment
        preset="apartment"
        environmentIntensity={controlsScene.envIntesity}
      />

      <color attach="background" args={["#000000"]} />
      <EffectComposer multisampling={1} disableNormalPass>
        <Bloom
          luminanceThreshold={controlsScene.luminanceThreshold}
          mipmapBlur={false}
          radius={controlsScene.radius}
          intensity={controlsScene.intensity}
        />
      </EffectComposer>

      <ButtonContact controlsScene={controlsScene} />
      <WindowWall controlsScene={controlsScene} materialWall={materialWall} />
      <LightFinal />
      <Soeme3 controlsScene={controlsScene} />
      <HandCartoon
        controlsScene={controlsScene}
        finishAnimation={finishAnimation}
        hideHand={hideHand}
        removeHand={removeHand}
      />
      {/* setStartThirdAnimation es la que activa las fisicas de la W */}
      <Character
        controlsScene={controlsScene}
        setStartThirdAnimation={setStartThirdAnimation}
        refCamera={refCamera}
        startSecondAnimation={startSecondAnimation}
        setStartUltimateAnimation={setStartUltimateAnimation}
        setLowSoundAmbient={setLowSoundAmbient}
        activateSound={activateSound}
        finishAnimationsCamera={finishAnimationsCamera}
        setFinishAnimation={setFinishAnimation}
      />
    </>
  );
};

export default Room;
