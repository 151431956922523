import { useFrame, useLoader, useThree } from "@react-three/fiber";
import { useEffect, useRef, useState } from "react";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import * as THREE from "three";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import { Environment, Lightformer } from "@react-three/drei";

const Glass = ({
  loadingComplete,
  setGlassFinished,
  glassFinished,
  removeGlass,
  activateSound,
}) => {
  const { scene, camera } = useThree();
  const [listenerSoundExplosion] = useState(() => new THREE.AudioListener());
  const [soundExplosion] = useState(
    () => new THREE.Audio(listenerSoundExplosion)
  );

  const refInit = useRef(false);
  const refMixers = useRef([]);
  const refReadyAnimations = useRef(false);
  const refAllClips = useRef([]);
  // const envMap = useLoader(RGBELoader, "/enviroments/enviroment5.hdr");
  const refModel = useRef();
  useEffect(() => {
    /**
     *
     * Sonido explosion
     *
     */
    const audioLoader = new THREE.AudioLoader();
    audioLoader.load("./sounds/explosion.mp3", function (buffer) {
      camera.add(listenerSoundExplosion);
      soundExplosion.setBuffer(buffer);
      // soundExplosion.setLoop(true);
      soundExplosion.setVolume(0.7);
      // soundExplosion.play();
    });
  }, []);
  useEffect(() => {
    if (!loadingComplete) return;
    setTimeout(() => {
      soundExplosion.play();
    }, 70);
    refMixers.current.forEach((mixer, index) => {
      mixer.clipAction(refAllClips.current[index]).loop = THREE.LoopOnce;
      mixer.clipAction(refAllClips.current[index]).clampWhenFinished = true;
      mixer.clipAction(refAllClips.current[index]).play();
    });
  }, [loadingComplete]);
  useEffect(() => {
    if (activateSound) {
      soundExplosion.setVolume(0.75);
    } else {
      soundExplosion.setVolume(0.0);
    }
  }, [activateSound]);
  useEffect(() => {
    if (refInit.current) return;
    refInit.current = true;
    (async () => {
      const options = {
        transmission: 1,
        thickness: 1.5,
        roughness: 0.47,
        metalness: 0.0,
        envMapIntensity: 10.0,
      };
      const loader = new GLTFLoader();
      const glb = await loader.loadAsync("models/vidrio4_juntos.glb");
      refModel.current = glb.scene;
      const material2 = new THREE.MeshPhysicalMaterial({
        // envMap: envMap,
        metalness: options.metalness,
        transmission: options.transmission,
        thickness: options.thickness,
        roughness: options.roughness,
        envMapIntensity: options.envMapIntensity,
      });

      refAllClips.current = glb.animations;
      for (let i = 0; i < refModel.current.children.length; i++) {
        const mesh = refModel.current.children[i];

        mesh.material = material2;
      }
      refAllClips.current.forEach((clip, index) => {
        if (clip.name.includes("Cube.001")) {
          const mixer = new THREE.AnimationMixer(refModel.current);
          refMixers.current.push(mixer);
        }
      });

      refReadyAnimations.current = true;
      refModel.current.scale.set(6.8, 5, 3);
      refModel.current.scale.set(10, 9, 3);
      // refModel.current.scale.x = window.innerWidth / 100;
      refModel.current.position.x = 110;
      // refModel.current.position.y = 0.75;
      refModel.current.position.y = 10;
      refModel.current.position.z = -7.5;
      refModel.current.rotation.y = Math.PI * 0.5;
      scene.add(refModel.current);
      refModel.current.children[0].visible = false;
      refModel.current.children[1].visible = false;
      refModel.current.children[2].visible = false;
      // refMixers.current.forEach((mixer, index) => {
      //   mixer.clipAction(glb.animations[index]).loop = THREE.LoopOnce;
      //   mixer.clipAction(glb.animations[index]).clampWhenFinished = true;
      //   mixer.clipAction(glb.animations[index]).play();
      // });
      // const resize = () => {
      //   // refModel.current.scale.x = window.innerWidth / 100;
      //   const aspectRatio = 16 / 9;
      //   const windowAspectRatio = window.innerWidth / window.innerHeight;

      //   if (window.innerWidth < 750) {
      //     // Ajustar la escala del objeto para pantallas más pequeñas
      //     refModel.current.scale.set(
      //       (window.innerWidth / 100) * 1.5,
      //       (window.innerWidth / aspectRatio / 100) * 1.5,
      //       3
      //     );
      //   } else {
      //     // Ajustar la escala del objeto manteniendo el aspect ratio 16/9
      //     if (windowAspectRatio > aspectRatio) {
      //       // La ventana es más ancha que el aspect ratio 16/9
      //       refModel.current.scale.set(
      //         (window.innerHeight * aspectRatio) / 100,
      //         window.innerHeight / 100,
      //         3
      //       );
      //     } else {
      //       // La ventana es más alta que el aspect ratio 16/9
      //       refModel.current.scale.set(
      //         window.innerWidth / 100,
      //         window.innerWidth / aspectRatio / 100,
      //         3
      //       );
      //     }
      //   }
      // };
      // window.addEventListener("resize", resize);
    })();
  }, []);

  useEffect(() => {
    if (!removeGlass) return;
    for (let i = 0; i < refModel.current.children.length; i++) {
      const item = refModel.current.children[i];

      item.geometry.dispose();
      item.material.dispose();
    }
    scene.remove(refModel.current);
  }, [removeGlass]);
  // useFrame((_, delta) => {
  //   if (refMixers.current.length > 0 && refReadyAnimations.current) {
  //     refMixers.current.forEach((mixer) => {
  //       if (mixer.time < 0.93) {
  //         mixer.update(delta * 10.7);
  //       } else {
  //         mixer.update(delta * 0.204);
  //       }
  //     });
  //   }
  // });

  useFrame((_, delta) => {
    if (refMixers.current.length > 0 && refReadyAnimations.current) {
      refMixers.current.forEach((mixer) => {
        mixer._actions.forEach((action) => {
          // if (action.time < 0.93) {
          if (action.time < 0.1) {
            mixer.update(delta * 0.2);
          } else if (action.time >= 0.1 && action.time < 0.3) {
            mixer.update(delta * 0.35);
          } else {
            mixer.update(delta * 0.1);
          }
          if (action.time > 0.5 && !glassFinished) {
            setGlassFinished(true);
          }
          // console.log("action", action.time);
        });
      });
    }
  });
  return null;
};

export default Glass;
