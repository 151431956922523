import { Suspense, useEffect, useRef, useState } from "react";
import Experience from "./components/Experience";
import { isMobile } from "react-device-detect";
import gsap from "gsap";
import { useProgress } from "@react-three/drei";
import LoadingDiv from "./components/Loading/LoadingDiv";
import { GiSoundOff } from "react-icons/gi";
import { GiSoundOn } from "react-icons/gi";
function App() {
  // const [loadingComplete, setLoadingComplete] = useState(false);
  // const [activateSound, setActivateSound] = useState(true);
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [activateSound, setActivateSound] = useState(true);
  const [valueCompliance, setValueCompliance] = useState(10.0);
  const [velocityFactorValue, setVelocityFactorValue] = useState(0.15);
  const refHand = useRef(null);

  useEffect(() => {
    console.log(
      "%cMade by SOWEME - GD",
      "background: black; color: white;font-size:20px;margin-top:20px;margin-bottom:20px;border:4px solid #bd20ff;padding:7px 20px"
    );
  }, []);
  if (isMobile) {
    return (
      <>
        <div
          style={{
            position: "fixed",
            left: "83%",
            top: "4%",
            zIndex: "200",
            background: "#FFFFFF",
            padding: "4px",
            borderRadius: "50%",
            opacity: "0.5",
          }}
        >
          {activateSound ? (
            <GiSoundOn
              style={{ fontSize: "30px", cursor: "pointer" }}
              onClick={() => setActivateSound(false)}
            />
          ) : (
            <GiSoundOff
              style={{ fontSize: "30px", cursor: "pointer" }}
              onClick={() => setActivateSound(true)}
            />
          )}
        </div>
        <LoadingDiv setLoadingComplete={setLoadingComplete} />
        <div
          style={{
            width: "100%",
            height: "100vh",
            background: "#000000",
          }}
        >
          <Suspense>
            <Experience
              refHand={refHand}
              loadingComplete={loadingComplete}
              activateSound={activateSound}
            />
          </Suspense>
          {/* <div
            style={{
              position: "absolute",

              zIndex: "10",
              display: "none",
            }}
            ref={refHand}
          >
            <img
              src="/images/hand.png"
              alt="hand"
              style={{
                width: "12vw",
                // width: "5%",
              }}
            />
          </div> */}
        </div>
      </>
    );
  } else {
    return (
      <>
        <LoadingDiv setLoadingComplete={setLoadingComplete} />
        {/* <div
          style={{
            position: "fixed",
            top: "0",
            left: "50%",
            transform: "translate(-50%)",
            zIndex: "10000",
            // display: "none",
          }}
        >
          <input
            type="range"
            min="0"
            max="700"
            step="0.5"
            id="complianceSlider"
            className="slider"
            onChange={(e) => setValueCompliance(e.target.value)}
            style={{ width: "900px" }}
            defaultValue={0}
            value={valueCompliance}
          />
          <br />
          <b style={{ color: "white" }}>Valor compliance: {valueCompliance}</b>
          <hr />
          <br />
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            id="input-velocity"
            onChange={(e) => setVelocityFactorValue(e.target.value)}
            style={{ width: "900px" }}
            // defaultValue={0.1}
            value={velocityFactorValue}
          />

          <br />
          <b style={{ color: "white" }}>
            Valor velocityFactor: {velocityFactorValue}
          </b>

          <button id="tet-visible">Mostrar tetraedros</button>
          <button id="grid-visible">Mostrar grid</button>
          <button id="ground-visible">Mostrar ground</button>
        </div> */}
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            background: "#000000",
          }}
        >
          <div
            style={{ width: "100%", aspectRatio: "16/9", position: "relative" }}
          >
            <div
              style={{
                position: "fixed",
                left: "90%",
                top: "4%",
                zIndex: "200",
                background: "#FFFFFF",
                padding: "4px",
                borderRadius: "50%",
                opacity: "0.5",
              }}
            >
              {activateSound ? (
                <GiSoundOn
                  style={{ fontSize: "30px", cursor: "pointer" }}
                  onClick={() => setActivateSound(false)}
                />
              ) : (
                <GiSoundOff
                  style={{ fontSize: "30px", cursor: "pointer" }}
                  onClick={() => setActivateSound(true)}
                />
              )}
            </div>
            <Suspense>
              <Experience
                refHand={refHand}
                loadingComplete={loadingComplete}
                activateSound={activateSound}
              />
            </Suspense>
            {/* <div
              style={{
                position: "absolute",
                left: "50%",
                transform: "translate(-50%, -64.9%)",
                zIndex: "10",
                display: "none",
              }}
              ref={refHand}
            >
              <img src="/images/hand.png" alt="hand" style={{ width: "5vw" }} />
            </div> */}
          </div>
        </div>
      </>
    );
  }
}

export default App;
