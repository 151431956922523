import { useGLTF } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import gsap from "gsap";
import { usePageVisibility } from "react-page-visibility";
import { isMobile } from "react-device-detect";
const CharacterAnimation = ({
  refCharacter,
  refCamera,
  controlsScene,
  setStartThirdAnimation,
  glb,
  setFirstCamera,
  setSecondCamera,
  setThirdCamera,
  initCharacterAnimation,
  setStartUltimateAnimation,
  activateSound,
  setFinishAnimation,
  finishFirstCamera,
}) => {
  const velocityAnimation = 0.6;
  const multiplyPosition = 60.4;
  const deltaTop = 0.2;
  const animationJumpForward = useGLTF("/animations/jump_forward_place.glb");
  const animationJump = useGLTF("/animations/jump.glb");
  //Posiciones donde termina la animacion
  // const firstJump = 0.047;
  const firstJump = 0.0505;
  // const firstPositionJump = 11.628999999999944;
  // const firstPositionJump = 11.217591419998824;
  const firstPositionJump = 11.624488100000715;

  // const secondJump = 0.0215;
  const secondJump = 0.0235;
  // const secondPositionJump = 9.465999999999863;
  const secondPositionJump = 9.238117319999898;

  // const thirdJump = 0.021;
  const thirdJump = 0.0215;
  const thirdPosition = 7.341756159999748;

  // const jumpDuration = 1.0333333015441895;
  const stopJumpAnimation = 0.885;
  const refMixer = useRef(new THREE.AnimationMixer(glb.scene));
  const refMixerJumpForward = useRef(new THREE.AnimationMixer(glb.scene));
  const refMixerJumpForward2 = useRef(new THREE.AnimationMixer(glb.scene));
  const refMixerJumpForward3 = useRef(new THREE.AnimationMixer(glb.scene));
  const refMixerIdle2 = useRef(new THREE.AnimationMixer(glb.scene));
  const refActionIdle = useRef();
  const isVisible = usePageVisibility();
  const refActionJumpForward = useRef();
  const refActionJumpForward2 = useRef();
  const refActionJumpForward3 = useRef();
  const refAnimationActive = useRef();
  const refHide = useRef(false);
  const refActionIdle2 = useRef();
  const refStateActivateIdle2 = useRef(false);
  const refExecuteJumpUltimate = useRef(false);
  const refExecuteJumpUltimate2 = useRef(false);

  const refActionJumpToMoveLetter = useRef();
  const refMixerJumpToMoveLetter = useRef(new THREE.AnimationMixer(glb.scene));
  const refActionJumpToMoveLetter2 = useRef();
  const refMixerJumpToMoveLetter2 = useRef(new THREE.AnimationMixer(glb.scene));
  const refActionIdle3 = useRef();
  const refMixerIdle3 = useRef(new THREE.AnimationMixer(glb.scene));

  const [listenerStep] = useState(() => new THREE.AudioListener());
  const [soundStep] = useState(() => new THREE.Audio(listenerStep));
  const [listenerHit] = useState(() => new THREE.AudioListener());
  const [soundHit] = useState(() => new THREE.Audio(listenerHit));
  const refTimeInitial = useRef(0);

  const refStateSoundStep1 = useRef(false);
  const refStateSoundStep2 = useRef(false);
  const refStateSoundStep3 = useRef(false);
  const refStateSoundHit = useRef(false);

  // useEffect(() => {
  //   if (initCharacterAnimation) {
  //     refActionIdle.current.fadeIn(0.5).play();
  //     refActionIdle3.current.stop();
  //     setFirstCamera(true);
  //   }
  // }, [initCharacterAnimation]);
  useEffect(() => {
    if (initCharacterAnimation) {
      // setFirstCamera(true);

      if (isMobile) {
        gsap.delayedCall(1.92, () => {
          refActionIdle.current.fadeIn(0.5).play();
          refActionIdle3.current.stop();
        });
      } else {
        gsap.delayedCall(0.1, () => {
          refActionIdle.current.fadeIn(0.5).play();
          refActionIdle3.current.stop();
        });
      }
    }
  }, [initCharacterAnimation]);
  // finishFirstCamera
  useEffect(() => {
    if (activateSound) {
      soundStep.setVolume(0.8);
      soundHit.setVolume(0.8);
    } else {
      soundStep.setVolume(0);
      soundHit.setVolume(0);
    }
  }, [activateSound]);
  useEffect(() => {
    /**
     *
     * Sonido step
     *
     */
    const audioLoader = new THREE.AudioLoader();
    audioLoader.load("./sounds/step.mp3", function (buffer) {
      refCamera.current.add(listenerStep);
      soundStep.setBuffer(buffer);
      soundStep.setLoop(false);
      soundStep.setVolume(1.0);
    });
    /**
     *
     * Sonido Hit
     *
     */
    audioLoader.load("./sounds/hit_object_cartoon.mp3", function (buffer) {
      refCamera.current.add(listenerHit);
      soundHit.setBuffer(buffer);
      soundHit.setLoop(false);
      soundHit.setVolume(0.86);
    });
  }, []);
  useFrame((state, delta) => {
    if (!isVisible) return;

    if (
      refMixer.current &&
      refMixerJumpForward.current &&
      refMixerJumpForward2.current &&
      refMixerJumpForward3.current &&
      refMixerIdle2.current &&
      refMixerJumpToMoveLetter.current &&
      refMixerJumpToMoveLetter2.current &&
      refMixerIdle3.current
    ) {
      refMixer.current.update(delta * velocityAnimation);
      refMixerJumpForward.current.update(delta * velocityAnimation);
      refMixerJumpForward2.current.update(delta * velocityAnimation);
      refMixerJumpForward3.current.update(delta * velocityAnimation);
      refMixerIdle2.current.update(delta * velocityAnimation);
      refMixerJumpToMoveLetter.current.update(delta * velocityAnimation);
      refMixerJumpToMoveLetter2.current.update(delta * velocityAnimation);
      refMixerIdle3.current.update(delta * velocityAnimation);

      if (refActionIdle.current && refActionIdle.current.isRunning()) {
        refAnimationActive.current = refActionJumpForward.current;
      } else if (
        refActionJumpForward.current &&
        refActionJumpForward.current.isRunning()
      ) {
        refAnimationActive.current = refActionJumpForward.current;
        if (refActionJumpForward.current.time > 0.1) {
          if (delta < deltaTop) {
            refCharacter.current.position.z -=
              delta * firstJump * multiplyPosition;
            const temp1 = firstPositionJump + 1.3;
            if (
              refActionJumpForward.current.time > 0.75 &&
              // refCharacter.current.position.z < temp1 &&
              !refStateSoundStep1.current
            ) {
              refStateSoundStep1.current = true;
              soundStep.play();
              setTimeout(() => {
                soundStep.play();
              }, 250);
            }
            // refCharacter.current.position.z -= firstJump;
            if (refActionJumpForward.current.time > 0.85) {
              refActionJumpForward.current.timeScale = 1.8;
            } else {
              refActionJumpForward.current.timeScale = 1;
            }
          }
        }
      } else if (
        refActionJumpForward2.current &&
        refActionJumpForward2.current.isRunning()
      ) {
        if (delta < deltaTop) {
          refCharacter.current.position.z -=
            delta * secondJump * multiplyPosition;
          // const temp2 = secondPositionJump + 0.5;
          if (
            refActionJumpForward2.current.time > 0.75 &&
            // refCharacter.current.position.z < temp2 &&
            !refStateSoundStep2.current
          ) {
            refStateSoundStep2.current = true;
            soundStep.play();
            setTimeout(() => {
              soundStep.play();
            }, 250);
          }
        }
        // refCharacter.current.position.z -= secondJump;
        refAnimationActive.current = refActionJumpForward2.current;
      } else if (
        refActionJumpForward3.current &&
        refActionJumpForward3.current.isRunning()
      ) {
        if (refActionJumpForward3.current.time < stopJumpAnimation) {
          if (delta < deltaTop) {
            refCharacter.current.position.z -=
              delta * thirdJump * multiplyPosition;
            // const temp3 = thirdPosition + 0.5;

            if (
              refActionJumpForward3.current.time > 0.75 &&
              // refCharacter.current.position.z < temp3 &&
              !refStateSoundStep3.current
            ) {
              refStateSoundStep3.current = true;
              soundStep.play();
              setTimeout(() => {
                soundStep.play();
              }, 250);
            }
          }
          // refCharacter.current.position.z -= thirdJump;
          refAnimationActive.current = refActionJumpForward3.current;
        } else if (!refStateActivateIdle2.current) {
          refStateActivateIdle2.current = true;
          refActionJumpForward3.current.timeScale = 0;
          functionActivateIdle2();
        }
      } else if (refActionIdle2.current && refActionIdle2.current.isRunning()) {
        refAnimationActive.current = refActionIdle2.current;
      } else if (
        refActionJumpToMoveLetter.current &&
        refActionJumpToMoveLetter.current.isRunning()
      ) {
        refAnimationActive.current = refActionJumpToMoveLetter.current;
        if (
          refActionJumpToMoveLetter.current.time > 1.15 &&
          !refExecuteJumpUltimate.current
        ) {
          refExecuteJumpUltimate.current = true;
          soundHit.play();

          // refActionJumpToMoveLetter2.current.play();
          refActionJumpToMoveLetter2.current.fadeIn(0.24).play();
          setTimeout(() => {
            refActionJumpToMoveLetter.current.stop();
          }, 250);
          gsap.to(refCharacter.current.position, {
            z: 3,
            duration: 2,
          });
          setStartThirdAnimation(true);
          setFinishAnimation(true);
          setTimeout(() => {
            setStartUltimateAnimation(true);
          }, 300);
        }
      } else if (
        refActionJumpToMoveLetter2.current &&
        refActionJumpToMoveLetter2.current.isRunning()
      ) {
        if (
          refActionJumpToMoveLetter2.current.time > 1.1 &&
          !refExecuteJumpUltimate2.current
        ) {
          refExecuteJumpUltimate2.current = true;
          soundStep.play();
        }
      }
    }
  });

  useEffect(() => {
    // if (refActionIdle.current && refActionJumpForward.current) {
    if (!isVisible && initCharacterAnimation) {
      refHide.current = true;
    }
    // }
  }, [isVisible]);

  useEffect(() => {
    // const animationJumpForwardClip = animationJumpForward.animations[1];
    const animationJumpForwardClip = animationJumpForward.animations.find(
      (clip) => clip.name === "Armature|mixamo.com|Layer0.001"
    );
    refActionJumpForward.current = refMixerJumpForward.current.clipAction(
      animationJumpForwardClip
    );
    // console.log("animationJumpForward", animationJumpForward);
    refActionJumpForward.current.clampWhenFinished = true;
    // Second mixer
    refActionJumpForward2.current = refMixerJumpForward2.current.clipAction(
      animationJumpForwardClip
    );
    refActionJumpForward2.current.clampWhenFinished = true;

    // Third mixer
    refActionJumpForward3.current = refMixerJumpForward3.current.clipAction(
      animationJumpForwardClip
    );
    refActionJumpForward3.current.clampWhenFinished = true;
    //Mixer Idle 1
    // const animationIdleClip = glb.animations[1];
    const animationIdleClip = glb.animations.find(
      (clip) => clip.name === "Armature|mixamo.com|Layer0.001"
    );
    refActionIdle.current = refMixer.current.clipAction(animationIdleClip);
    refActionIdle.current.clampWhenFinished = true;
    refActionIdle.current.timeScale = 1.65;
    refActionIdle.current.setLoop(THREE.LoopOnce, 1);
    //Mixer Idle 2
    refActionIdle2.current =
      refMixerIdle2.current.clipAction(animationIdleClip);
    refActionIdle2.current.clampWhenFinished = true;
    refActionIdle2.current.timeScale = 2;
    refActionIdle2.current.setLoop(THREE.LoopOnce, 1);
    //Mixer Idle 3
    refActionIdle3.current =
      refMixerIdle3.current.clipAction(animationIdleClip);
    refActionIdle3.current.clampWhenFinished = true;
    refActionIdle3.current.timeScale = 1.65;

    //Aca hago play para mantener este idle
    refActionIdle3.current.play();

    //Jump to move letter
    // const animationJumpClip = animationJump.animations[0];
    // console.log("glb jump", animationJump);
    const animationJumpClip = animationJump.animations.find(
      (clip) => clip.name === "Armature|mixamo.com|Layer0"
    );
    refActionJumpToMoveLetter.current =
      refMixerJumpToMoveLetter.current.clipAction(animationJumpClip);
    refActionJumpToMoveLetter.current.clampWhenFinished = true;

    //Jump 2 to move letter
    refActionJumpToMoveLetter2.current =
      refMixerJumpToMoveLetter2.current.clipAction(animationJumpClip);
    refActionJumpToMoveLetter2.current.clampWhenFinished = true;
    refActionJumpToMoveLetter2.current.setLoop(THREE.LoopOnce, 1);
    refActionJumpToMoveLetter2.current.time = 0.5;

    setTimeout(() => {
      gsap.to(refCharacter.current.rotation, {
        y: Math.PI,
        duration: 0.75,
        onComplete: () => {
          // refActionIdle.current.stop();
        },
      });
    }, 2000);

    const onMixerFinished = (event) => {
      if (event.action === refAnimationActive.current) {
        if (isVisible && refAnimationActive.current && refHide.current) {
          refCharacter.current.position.z = controlsScene.positionCharacterZ;
          refAnimationActive.current.reset().play();
          refHide.current = false;
          refAnimationActive.current = null;
          return;
        }
      }
      refActionJumpForward.current.setLoop(THREE.LoopOnce, 1);
      refActionJumpForward.current.fadeIn(0.5).play();
      setFirstCamera(false);
      setSecondCamera(true);

      // Aquí puedes manejar lo que deseas que ocurra al finalizar la animación
    };

    const onMixerFinishedJumpForward = (event) => {
      // console.log("Animation jump finished", event);
      if (event.action === refAnimationActive.current) {
        // console.log("estado de jump true");
        if (isVisible && refAnimationActive.current && refHide.current) {
          refCharacter.current.position.z = controlsScene.positionCharacterZ;
          refAnimationActive.current.reset().play();
          refHide.current = false;
          refAnimationActive.current = null;
          return;
        }
      }
      // refCharacter.current.position.z = firstPositionJump;
      // refActionJumpForward.current.fadeOut(0.5);
      refActionJumpForward2.current.setLoop(THREE.LoopOnce, 1);
      // refActionJumpForward2.current.fadeIn(0.5).play();
      refActionJumpForward2.current.play();
      // Aquí puedes manejar lo que deseas que ocurra al finalizar la animación<
    };
    const onMixerFinishedJumpForward2 = (event) => {
      if (event.action === refAnimationActive.current) {
        if (isVisible && refAnimationActive.current && refHide.current) {
          refCharacter.current.position.z = firstPositionJump;
          refAnimationActive.current.reset().play();
          refHide.current = false;
          refAnimationActive.current = null;
          return;
        }
      }
      // refActionJumpForward2.current.fadeOut(0.5);
      refActionJumpForward3.current.setLoop(THREE.LoopOnce, 1);
      // refActionJumpForward3.current.fadeIn(0.5).play();
      refActionJumpForward3.current.play();
    };
    const onMixerFinishedJumpForward3 = (event) => {
      if (event.action === refAnimationActive.current) {
        if (isVisible && refAnimationActive.current && refHide.current) {
          refCharacter.current.position.z = secondPositionJump;
          refAnimationActive.current.reset().play();
          refHide.current = false;
          refAnimationActive.current = null;
          return;
        }
        // Este no tiene play porque se ejecuta functionActivateIdle2 en el useFrame
      }
    };
    const onMixerFinishedIdle2 = (event) => {
      if (event.action === refAnimationActive.current) {
        if (isVisible && refAnimationActive.current && refHide.current) {
          refCharacter.current.position.z = thirdPosition;
          refAnimationActive.current.reset().play();
          refHide.current = false;
          refAnimationActive.current = null;
          return;
        }
      }
      // refActionJumpForward2.current.fadeOut(0.5);
      refActionJumpToMoveLetter.current.setLoop(THREE.LoopOnce, 1);
      refActionJumpToMoveLetter.current.fadeIn(0.5).play();
      setSecondCamera(false);
      setThirdCamera(true);
    };

    const onMixerFinishedJumpToMoveLetter = (event) => {
      if (event.action === refAnimationActive.current) {
        if (isVisible && refAnimationActive.current && refHide.current) {
          refCharacter.current.position.z = thirdPosition;
          refAnimationActive.current.reset().play();
          refHide.current = false;
          refAnimationActive.current = null;
          return;
        }
      }
    };
    refMixer.current.addEventListener("finished", onMixerFinished);
    refMixerJumpForward.current.addEventListener(
      "finished",
      onMixerFinishedJumpForward
    );
    refMixerJumpForward2.current.addEventListener(
      "finished",
      onMixerFinishedJumpForward2
    );
    refMixerJumpForward3.current.addEventListener(
      "finished",
      onMixerFinishedJumpForward3
    );
    refMixerIdle2.current.addEventListener("finished", onMixerFinishedIdle2);

    refMixerJumpToMoveLetter.current.addEventListener(
      "finished",
      onMixerFinishedJumpToMoveLetter
    );
    // window.addEventListener("keydown", (e) => {
    //   if (e.key === "q") {
    //     console.log(refCharacter.current.position.z);
    //   }
    // });

    return () => {
      if (refMixer.current) {
        refMixer.current.removeEventListener("finished", onMixerFinished);
      }
      if (refMixerJumpForward.current) {
        refMixerJumpForward.current.removeEventListener(
          "finished",
          onMixerFinishedJumpForward
        );
      }
      if (refMixerJumpForward2.current) {
        refMixerJumpForward2.current.removeEventListener(
          "finished",
          onMixerFinishedJumpForward2
        );
      }
      if (refMixerJumpForward3.current) {
        refMixerJumpForward3.current.removeEventListener(
          "finished",
          onMixerFinishedJumpForward3
        );
      }
      if (refMixerIdle2.current) {
        refMixerIdle2.current.removeEventListener(
          "finished",
          onMixerFinishedIdle2
        );
      }
      if (refMixerJumpToMoveLetter.current) {
        refMixerJumpToMoveLetter.current.removeEventListener(
          "finished",
          onMixerFinishedJumpToMoveLetter
        );
      }
    };
  }, []);
  const functionActivateIdle2 = () => {
    refActionIdle2.current.fadeIn(0.5).play();
    gsap.to(refCharacter.current.rotation, {
      y: Math.PI * 0.5,
      duration: 0.5,
    });
  };
  return null;
};

export default CharacterAnimation;
