import { useEffect, useState } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/addons/controls/OrbitControls.js";
import { LetterTetMesh2, LetterVisTetMesh2 } from "../data/LetterData";
import { LetterInitialPosition } from "./../data/LetterData";
import Stats from "three/examples/jsm/libs/stats.module";
import { useRef } from "react";
import { EffectComposer } from "three/addons/postprocessing/EffectComposer.js";
import { RenderPass } from "three/addons/postprocessing/RenderPass.js";
import { UnrealBloomPass } from "three/addons/postprocessing/UnrealBloomPass.js";
import { OutputPass } from "three/addons/postprocessing/OutputPass.js";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import * as BufferGeometryUtils from "three/addons/utils/BufferGeometryUtils.js";
import { useThree } from "@react-three/fiber";
import { isMobile } from "react-device-detect";

import gsap from "gsap";
const Init = ({
  refCamera,
  startThirdAnimation,
  activateSound,
  setStartThirdAnimation,
  setFinishAnimation,
  setHideHand,
  finishAnimation,
  setRemoveHand,
}) => {
  const { scene, gl } = useThree();
  const [valueCompliance, setValueCompliance] = useState(0.0);
  const [velocityFactorValue, setVelocityFactorValue] = useState(1);
  const [loading, setLoading] = useState(true);
  const { camera } = useThree();
  const [listenerSoundSlip] = useState(() => new THREE.AudioListener());
  const [soundSlip] = useState(() => new THREE.Audio(listenerSoundSlip));
  const [listenerSplash] = useState(() => new THREE.AudioListener());
  const [soundSplash] = useState(() => new THREE.Audio(listenerSplash));
  const [isPlaying, setIsPlaying] = useState(false);
  const refGUI = useRef(null);
  const refDeltaVelocity = useRef(0.016);
  const refInit = useRef(false);
  const refActivateGUI = useRef(false);
  const tlRef = useRef(null);
  const gPhysicsScene = {
    // gravity: [0.0, -10.0, 0.0],
    // gravity: [0.0, -5.0, 0.0],
    gravity: [0.0, -12.0, 0.0],
    // gravity: [0.0, -15, 0.0],
    // gravity: [0.0, -0.5, 0.0],
    dt: 1.0 / 60.0,
    // dt: 1.0 / 76.0,
    // numSubsteps: 10,
    numSubsteps: 3,
    paused: true,
    showTets: false,
    objects: [],
  };
  const refPhysicsScene = useRef(gPhysicsScene);

  useEffect(() => {
    /**
     *
     * Sonido slip
     *
     */
    const audioLoader = new THREE.AudioLoader();
    audioLoader.load("./sounds/slip.mp3", function (buffer) {
      camera.add(listenerSoundSlip);
      soundSlip.setBuffer(buffer);
      soundSlip.setLoop(false);
      soundSlip.setVolume(0.5);
      // soundSlip.play();
    });
    /**
     *
     * Sonido splash
     *
     */
    audioLoader.load("./sounds/splash2.mp3", function (buffer) {
      camera.add(listenerSplash);
      soundSplash.setBuffer(buffer);
      soundSplash.setLoop(false);
      soundSplash.setVolume(0.5);
      // soundSlip.play();
    });
  }, []);
  useEffect(() => {
    if (activateSound) {
      soundSlip.setVolume(0.5);
    } else {
      soundSlip.setVolume(0);
    }
  }, [activateSound]);
  useEffect(() => {
    // document.querySelector("#gravedad").addEventListener("change", (e) => {
    //   console.log("e.target.value", e.target.value);
    //   refPhysicsScene.current.gravity[1] = parseFloat(e.target.value);
    // });
    // console.log("startThirdAnimation", startThirdAnimation);
    if (startThirdAnimation && !isMobile) {
      setTimeout(() => {
        refPhysicsScene.current.paused = false;
      }, 130);
    }
  }, [startThirdAnimation]);
  useEffect(() => {
    if (finishAnimation && isMobile) {
      setTimeout(() => {
        refPhysicsScene.current.paused = false;
      }, 130);
    }
  }, [finishAnimation]);
  useEffect(() => {
    if (refInit.current) return;
    refInit.current = true;
    // refGUI.current = new GUI();
    // if (!refActivateGUI.current) {
    //   GUI.toggleHide();
    // }

    let stats;

    if (refActivateGUI.current) {
      stats = new Stats();
      document.body.appendChild(stats.dom);
    }

    function vecSetZero(a, anr) {
      anr *= 3;
      a[anr++] = 0.0;
      a[anr++] = 0.0;
      a[anr] = 0.0;
    }

    function vecScale(a, anr, scale) {
      anr *= 3;
      a[anr++] *= scale;
      a[anr++] *= scale;
      a[anr] *= scale;
    }

    function vecCopy(a, anr, b, bnr) {
      anr *= 3;
      bnr *= 3;
      a[anr++] = b[bnr++];
      a[anr++] = b[bnr++];
      a[anr] = b[bnr];
    }

    function vecAdd(a, anr, b, bnr, scale = 1.0) {
      anr *= 3;
      bnr *= 3;
      a[anr++] += b[bnr++] * scale;
      a[anr++] += b[bnr++] * scale;
      a[anr] += b[bnr] * scale;
    }

    function vecSetDiff(dst, dnr, a, anr, b, bnr, scale = 1.0) {
      dnr *= 3;
      anr *= 3;
      bnr *= 3;
      dst[dnr++] = (a[anr++] - b[bnr++]) * scale;
      dst[dnr++] = (a[anr++] - b[bnr++]) * scale;
      dst[dnr] = (a[anr] - b[bnr]) * scale;
    }

    function vecLengthSquared(a, anr) {
      anr *= 3;
      let a0 = a[anr],
        a1 = a[anr + 1],
        a2 = a[anr + 2];
      return a0 * a0 + a1 * a1 + a2 * a2;
    }

    function vecDistSquared(a, anr, b, bnr) {
      anr *= 3;
      bnr *= 3;
      let a0 = a[anr] - b[bnr],
        a1 = a[anr + 1] - b[bnr + 1],
        a2 = a[anr + 2] - b[bnr + 2];
      return a0 * a0 + a1 * a1 + a2 * a2;
    }

    function vecDot(a, anr, b, bnr) {
      anr *= 3;
      bnr *= 3;
      return (
        a[anr] * b[bnr] + a[anr + 1] * b[bnr + 1] + a[anr + 2] * b[bnr + 2]
      );
    }

    function vecSetCross(a, anr, b, bnr, c, cnr) {
      anr *= 3;
      bnr *= 3;
      cnr *= 3;
      a[anr++] = b[bnr + 1] * c[cnr + 2] - b[bnr + 2] * c[cnr + 1];
      a[anr++] = b[bnr + 2] * c[cnr + 0] - b[bnr + 0] * c[cnr + 2];
      a[anr] = b[bnr + 0] * c[cnr + 1] - b[bnr + 1] * c[cnr + 0];
    }

    function matGetDeterminant(A) {
      let a11 = A[0],
        a12 = A[3],
        a13 = A[6];
      let a21 = A[1],
        a22 = A[4],
        a23 = A[7];
      let a31 = A[2],
        a32 = A[5],
        a33 = A[8];
      return (
        a11 * a22 * a33 +
        a12 * a23 * a31 +
        a13 * a21 * a32 -
        a13 * a22 * a31 -
        a12 * a21 * a33 -
        a11 * a23 * a32
      );
    }

    function matSetMult(A, a, anr, b, bnr) {
      bnr *= 3;
      var bx = b[bnr++];
      var by = b[bnr++];
      var bz = b[bnr];
      vecSetZero(a, anr);
      vecAdd(a, anr, A, 0, bx);
      vecAdd(a, anr, A, 1, by);
      vecAdd(a, anr, A, 2, bz);
    }

    function matSetInverse(A) {
      let det = matGetDeterminant(A);
      if (det == 0.0) {
        // for (let i = 0; i < 9; i++) {
        //   // console.log("number 1");
        //   A[anr + i] = 0.0;
        //   // A[ i] = 0.0;
        //   return;
        // }
      }
      let invDet = 1.0 / det;
      let a11 = A[0],
        a12 = A[3],
        a13 = A[6];
      let a21 = A[1],
        a22 = A[4],
        a23 = A[7];
      let a31 = A[2],
        a32 = A[5],
        a33 = A[8];
      A[0] = (a22 * a33 - a23 * a32) * invDet;
      A[3] = -(a12 * a33 - a13 * a32) * invDet;
      A[6] = (a12 * a23 - a13 * a22) * invDet;
      A[1] = -(a21 * a33 - a23 * a31) * invDet;
      A[4] = (a11 * a33 - a13 * a31) * invDet;
      A[7] = -(a11 * a23 - a13 * a21) * invDet;
      A[2] = (a21 * a32 - a22 * a31) * invDet;
      A[5] = -(a11 * a32 - a12 * a31) * invDet;
      A[8] = (a11 * a22 - a12 * a21) * invDet;
    }

    // ------------------------------------------------------------------
    class Hash {
      constructor(spacing, maxNumObjects) {
        this.spacing = spacing;
        this.tableSize = 2 * maxNumObjects;
        this.cellStart = new Int32Array(this.tableSize + 1);
        this.cellEntries = new Int32Array(maxNumObjects);
        this.queryIds = new Int32Array(maxNumObjects);
        this.querySize = 0;
      }

      hashCoords(xi, yi, zi) {
        var h = (xi * 92837111) ^ (yi * 689287499) ^ (zi * 283923481); // fantasy function
        return Math.abs(h) % this.tableSize;
      }

      intCoord(coord) {
        return Math.floor(coord / this.spacing);
      }

      hashPos(pos, nr) {
        return this.hashCoords(
          this.intCoord(pos[3 * nr]),
          this.intCoord(pos[3 * nr + 1]),
          this.intCoord(pos[3 * nr + 2])
        );
      }

      create(pos) {
        var numObjects = Math.min(pos.length / 3, this.cellEntries.length);

        // determine cell sizes

        this.cellStart.fill(0);
        this.cellEntries.fill(0);

        for (var i = 0; i < numObjects; i++) {
          var h = this.hashPos(pos, i);
          this.cellStart[h]++;
        }

        // determine cells starts

        var start = 0;
        for (var i = 0; i < this.tableSize; i++) {
          start += this.cellStart[i];
          this.cellStart[i] = start;
        }
        this.cellStart[this.tableSize] = start; // guard

        // fill in objects ids

        for (var i = 0; i < numObjects; i++) {
          var h = this.hashPos(pos, i);
          this.cellStart[h]--;
          this.cellEntries[this.cellStart[h]] = i;
        }
      }

      query(pos, nr, maxDist) {
        var x0 = this.intCoord(pos[3 * nr] - maxDist);
        var y0 = this.intCoord(pos[3 * nr + 1] - maxDist);
        var z0 = this.intCoord(pos[3 * nr + 2] - maxDist);

        var x1 = this.intCoord(pos[3 * nr] + maxDist);
        var y1 = this.intCoord(pos[3 * nr + 1] + maxDist);
        var z1 = this.intCoord(pos[3 * nr + 2] + maxDist);

        this.querySize = 0;

        for (var xi = x0; xi <= x1; xi++) {
          for (var yi = y0; yi <= y1; yi++) {
            for (var zi = z0; zi <= z1; zi++) {
              var h = this.hashCoords(xi, yi, zi);
              var start = this.cellStart[h];
              var end = this.cellStart[h + 1];

              for (var i = start; i < end; i++) {
                this.queryIds[this.querySize] = this.cellEntries[i];
                this.querySize++;
              }
            }
          }
        }
      }
    }

    var gThreeScene = scene;
    var gRenderer = gl;
    var gComposer;
    var gCamera = refCamera.current;
    var gCameraControl;
    var gGrabber;
    var gMouseDown = false;

    // ------------------------------------------------------------------

    // ------------------------------------------------------------------
    class SoftBody {
      constructor(
        tetMesh,
        visMesh,
        scene,
        // edgeCompliance = 23.0,
        // edgeCompliance = 0.0,
        edgeCompliance = 170.0,
        volCompliance = 0.0
      ) {
        // this.scaleVertices(tetMesh.verts, 1.5);
        // this.scaleVertices(visMesh.verts, 20.0);
        this.adjustVertexHeight(tetMesh.verts, -0.05, "y");
        // 	this.adjustVertexHeight(visMesh.verts, 2, 'z');
        this.restore = false;
        this.hasTouchedFloor = false; // Nuevo
        this.firstPosition = new Float32Array(LetterInitialPosition);
        // this.firstPosition = null;
        this.arrayPositions = [];
        this.pointerDown = false;
        this.originalPos = {};

        // physics
        this.numParticles = tetMesh.verts.length / 3;
        this.numTets = tetMesh.tetIds.length / 4;
        this.pos = new Float32Array(tetMesh.verts);
        this.prevPos = tetMesh.verts.slice();
        this.vel = new Float32Array(3 * this.numParticles);
        this.firstTets = null;
        this.tetIds = tetMesh.tetIds;
        this.edgeIds = tetMesh.edgeIds;
        this.restVol = new Float32Array(this.numTets);
        this.edgeLengths = new Float32Array(this.edgeIds.length / 2);
        this.invMass = new Float32Array(this.numParticles);

        this.edgeCompliance = edgeCompliance;
        this.volCompliance = volCompliance;

        this.temp = new Float32Array(4 * 3);
        this.grads = new Float32Array(4 * 3);

        this.grabId = -1;
        this.grabInvMass = 0.0;

        this.initPhysics();

        // visual tet mesh

        var geometry = new THREE.BufferGeometry();
        geometry.setAttribute(
          "position",
          new THREE.BufferAttribute(this.pos, 3)
        );
        geometry.setIndex(tetMesh.edgeIds);
        // Escalar la geometría
        // const scaleVector = new THREE.Vector3(10, 10, 10);
        // geometry.scale(scaleVector.x, scaleVector.y, scaleVector.z);
        var lineMaterial = new THREE.LineBasicMaterial({
          color: 0x0000ff,
          linewidth: 2,
        });
        // this.scaleVertices2(geometry, 10.0);
        this.tetMesh = new THREE.LineSegments(geometry, lineMaterial);
        this.tetMesh.visible = false;
        // if (document.querySelector("#tet-visible")) {
        //   document
        //     .querySelector("#tet-visible")
        //     .addEventListener("click", () => {
        //       this.tetMesh.visible = !this.tetMesh.visible;
        //     });
        // }
        // if (document.querySelector("#complianceSlider")) {
        //   document
        //     .querySelector("#complianceSlider")
        //     .addEventListener("onchange", () => {
        //       this.edgeCompliance =
        //         document.querySelector("#complianceSlider").value;
        //     });
        // }
        // complianceSlider
        scene.add(this.tetMesh);

        /**
         *
         *
         * VisMesh
         *
         *
         */
        // this.numVisVerts = vertsMesh.length / 3;
        // this.computeSkinningInfo(vertsMesh);
        // geometry.setIndex(triIdsMesh);
        let verts = geometry.getAttribute("position").array;
        let triIds = geometry.getIndex().array;
        const vertsMesh = [...verts];
        const triIdsMesh = [...triIds];

        this.numVisVerts = visMesh.verts.length / 3;
        this.skinningInfo = new Float32Array(4 * this.numVisVerts);
        this.computeSkinningInfo(visMesh.verts);

        geometry = new THREE.BufferGeometry();
        geometry.setAttribute(
          "position",
          new THREE.BufferAttribute(new Float32Array(3 * this.numVisVerts), 3)
        );
        geometry.setIndex(visMesh.triIds);
        // const originalUVs = model.children[0].geometry.attributes.uv; //o array
        // const cloneOriginalUVs = originalUVs.clone();
        // geometry.setAttribute("uv", cloneOriginalUVs);
        // new THREE.BufferAttribute(new Float32Array(originalUVs), 2)
        // const geometryClone = model.children[0].geometry.clone();
        //Merge two geometries

        //otro ejemplo
        // geometry.setAttribute("uv", new THREE.BufferAttribute(originalUVs, 2));
        //         // Asumiendo que tienes una nueva geometría llamada newGeometry
        // var newGeometry = new THREE.BufferGeometry();
        // // Configura los vértices de newGeometry como sea necesario

        // // Asegúrate de que la cantidad de vértices sea compatible
        // if (newGeometry.attributes.position.count * 2 === originalUVs.length) {
        //   // Asigna las coordenadas UV extraídas a la nueva geometría
        //   newGeometry.setAttribute('uv', new THREE.BufferAttribute(new Float32Array(originalUVs), 2));
        // } else {
        //   console.error('La cantidad de vértices no es compatible entre las geometrías.');
        // }
        // const cubeTextureLoader = new THREE.CubeTextureLoader();
        // const environmentMap = cubeTextureLoader.load([
        //   "/cubemap/px.png",
        //   "/cubemap/nx.png",
        //   "/cubemap/py.png",
        //   "/cubemap/ny.png",
        //   "/cubemap/pz.png",
        //   "/cubemap/nz.png",
        // ]);
        // var visMaterial = new THREE.MeshStandardMaterial({
        //   color: 0x800080,
        //   // metalness: 1.0,
        //   // roughness: 0,
        //   // wireframe: true,
        //   // emissive: 0x44001c,
        //   // envMapIntensity: 20,
        //   // envMap: environmentMap,
        // });
        // const visMaterial = new THREE.MeshMatcapMaterial({
        //   color: 0x800080,
        // });
        const visMaterial = new THREE.MeshStandardMaterial({
          color: 0x800080,
        });
        // console.log("material map", material.map);
        // const visMaterial = new THREE.MeshBasicMaterial({
        //   map: material.map,
        // });
        if (refActivateGUI.current) {
          const visMaterialFolder = refGUI.current.addFolder("Material");
          var conf = { color: "#44001c" };
          visMaterialFolder
            .addColor(conf, "color")
            .onChange(function (colorValue) {
              visMaterial.emissive.set(colorValue);
            });
          visMaterialFolder.open();
          // visMaterialFolder.add(visMaterial, "metalness", 0, 1, 0.1);
        }
        // const visMaterial = model.children[0].material;

        // const texture = new THREE.TextureLoader().load(
        //   "/textures/Robot_mix_Albedo.png"
        // );
        // texture.encoding = THREE.SRGBColorSpace;
        // const materialClone = model.children[0].material.clone();
        // materialClone.map = texture;
        // console.log("texture", texture);
        //   const visMaterial = new THREE.MeshStandardMaterial({
        //     map: albedoTexture, // Textura albedo
        //     emissiveMap: emissionTexture, // Textura de emisión
        //     metalnessMap: metalnessTexture, // Textura de metalness
        //     normalMap: normalTexture // Textura normal
        // });
        // const visMaterial = new THREE.MeshStandardMaterial({
        //   // color: 0xff0000,
        //   map: texture,
        // });

        // model.traverse(function (child) {
        //   if (child.isMesh) {
        //     var geometry = child.geometry;
        //     if (geometry.attributes.uv) {
        //       // Aquí tienes acceso a las coordenadas UV
        //       var uvs = geometry.attributes.uv;
        //       // Puedes hacer algo con las coordenadas UV aquí
        //       console.log("uvs", uvs);
        //     }
        //   }
        // });
        // geometry.scale(1000.0, 1000.0, 1000.0);
        this.visMesh = new THREE.Mesh(geometry, visMaterial);
        this.visMesh.scale.set(4.6, 4.6, 4.6);
        this.tetMesh.scale.set(4.6, 4.6, 4.6);

        // this.visMesh.position.set(-50, 18.65, 1.7);
        // this.tetMesh.position.set(-50, 18.65, 1.7);
        this.visMesh.position.set(-50, 18.73, 7.36);
        this.tetMesh.position.set(-50, 18.73, 7.36);
        this.visMesh.rotation.set(0, Math.PI * 0.5, 0);
        this.tetMesh.rotation.set(0, Math.PI * 0.5, 0);
        // this.visMesh.geometry.scale(20.0, 20.0, 20.0);
        // this.tetMesh.geometry.scale(20.0, 20.0, 20.0);
        // this.visMesh.traverse(function (child) {
        //   if (child.isMesh) {
        //     child.material.envMapIntensity = 30;
        //     child.material.roughness = 0.147;
        //     child.material.metalness = 1.0;
        //   }
        // });
        // this.visMesh = model;
        this.visMesh.receiveShadow = true;
        this.visMesh.castShadow = true;
        this.visMesh.userData = this; // for raycasting
        this.visMesh.layers.enable(1);
        scene.add(this.visMesh);
        geometry.computeVertexNormals();
        this.updateVisMesh();

        this.volIdOrder = [
          [1, 3, 2],
          [0, 2, 3],
          [0, 3, 1],
          [0, 1, 2],
        ];

        const attachCamera = () => {
          // gCamera.lookAt(this.visMesh.position);
          const cameraOffset = new THREE.Vector3(0.0, 2.0, -0.1); // NOTE Constant offset between the camera and the target

          const geometry = this.visMesh.geometry;
          const positionAttribute = geometry.getAttribute("position");

          const vertex = new THREE.Vector3();
          vertex.fromBufferAttribute(positionAttribute, 2);

          this.visMesh.localToWorld(vertex);
          // NOTE Assuming the camera is direct child of the Scene
          // const objectPosition = new THREE.Vector3();
          // this.tetMesh.getWorldPosition(objectPosition);

          gCamera.position.copy(vertex).add(cameraOffset);
          window.requestAnimationFrame(attachCamera);
        };
        // attachCamera();
        // this.pos = new Float32Array([...this.firstPosition]);
        // this.ajustarValores(0.1);
        // this.eventListener();
      }

      scaleVertices2(geometry, scale) {
        for (let i = 0; i < geometry.attributes.position.array.length; i++) {
          // geometry.attributes.position.array[i] *= 0.1;
          geometry.attributes.position.array[i] *= scale;
        }
      }
      scaleVertices(vertices, scale) {
        for (let i = 0; i < vertices.length; i++) {
          vertices[i] *= scale;
        }
      }
      adjustVertexHeight(vertices, value, axis) {
        let index;
        switch (axis) {
          case "x":
            index = 0;
            break;
          case "y":
            index = 1;
            break;
          case "z":
            index = 2;
            break;
          default:
            console.error("Invalid axis:", axis);
            return;
        }
        for (let i = index; i < vertices.length; i += 3) {
          vertices[i] += value;
        }
      }
      // eventListener() {
      //   window.addEventListener("keydown", (e) => {
      //     if (e.key === "d") {
      //       let a = document.createElement("a");
      //       a.href =
      //         "data:text/plain;charset=utf-8," + encodeURIComponent(this.pos);
      //       a.download = "thisPos.txt";
      //       document.body.appendChild(a); // Agregar el elemento al DOM para hacer el click
      //       a.click();
      //     }
      //   });
      // }

      computeSkinningInfo(visVerts) {
        // create a hash for all vertices of the visual mesh

        var hash = new Hash(0.05, this.numVisVerts);
        hash.create(visVerts);

        this.skinningInfo.fill(-1.0); // undefined

        var minDist = new Float32Array(this.numVisVerts);
        minDist.fill(Number.MAX_VALUE);
        var border = 0.05;
        // var border = 1.1;

        // each tet searches for containing vertices

        var tetCenter = new Float32Array(3);
        var mat = new Float32Array(9);
        var bary = new Float32Array(4);
        for (var i = 0; i < this.numTets; i++) {
          // compute bounding sphere of tet

          tetCenter.fill(0.0);
          for (var j = 0; j < 4; j++)
            vecAdd(tetCenter, 0, this.pos, this.tetIds[4 * i + j], 0.25);

          var rMax = 0.0;
          for (var j = 0; j < 4; j++) {
            var r2 = vecDistSquared(
              tetCenter,
              0,
              this.pos,
              this.tetIds[4 * i + j]
            );
            rMax = Math.max(rMax, Math.sqrt(r2));
          }

          rMax += border;

          hash.query(tetCenter, 0, rMax);
          if (hash.queryIds.length == 0) continue;

          var id0 = this.tetIds[4 * i];
          var id1 = this.tetIds[4 * i + 1];
          var id2 = this.tetIds[4 * i + 2];
          var id3 = this.tetIds[4 * i + 3];

          vecSetDiff(mat, 0, this.pos, id0, this.pos, id3);
          vecSetDiff(mat, 1, this.pos, id1, this.pos, id3);
          vecSetDiff(mat, 2, this.pos, id2, this.pos, id3);

          matSetInverse(mat);

          for (var j = 0; j < hash.queryIds.length; j++) {
            var id = hash.queryIds[j];

            // we already have skinning info

            if (minDist[id] <= 0.0) continue;

            if (vecDistSquared(visVerts, id, tetCenter, 0) > rMax * rMax)
              continue;

            // compute barycentric coords for candidate

            vecSetDiff(bary, 0, visVerts, id, this.pos, id3);
            matSetMult(mat, bary, 0, bary, 0);
            bary[3] = 1.0 - bary[0] - bary[1] - bary[2];

            var dist = 0.0;
            for (var k = 0; k < 4; k++) dist = Math.max(dist, -bary[k]);

            if (dist < minDist[id]) {
              minDist[id] = dist;
              this.skinningInfo[4 * id] = i;
              this.skinningInfo[4 * id + 1] = bary[0];
              this.skinningInfo[4 * id + 2] = bary[1];
              this.skinningInfo[4 * id + 3] = bary[2];
            }
          }
        }
      }

      getTetVolume(nr) {
        var id0 = this.tetIds[4 * nr];
        var id1 = this.tetIds[4 * nr + 1];
        var id2 = this.tetIds[4 * nr + 2];
        var id3 = this.tetIds[4 * nr + 3];
        vecSetDiff(this.temp, 0, this.pos, id1, this.pos, id0);
        vecSetDiff(this.temp, 1, this.pos, id2, this.pos, id0);
        vecSetDiff(this.temp, 2, this.pos, id3, this.pos, id0);
        vecSetCross(this.temp, 3, this.temp, 0, this.temp, 1);
        return vecDot(this.temp, 3, this.temp, 2) / 6.0;
      }

      initPhysics() {
        this.invMass.fill(0.0);
        this.restVol.fill(0.0);

        for (var i = 0; i < this.numTets; i++) {
          var vol = this.getTetVolume(i);
          this.restVol[i] = vol;
          var pInvMass = vol > 0.0 ? 1.0 / (vol / 4.0) : 0.0;
          this.invMass[this.tetIds[4 * i]] += pInvMass;
          this.invMass[this.tetIds[4 * i + 1]] += pInvMass;
          this.invMass[this.tetIds[4 * i + 2]] += pInvMass;
          this.invMass[this.tetIds[4 * i + 3]] += pInvMass;
        }
        for (var i = 0; i < this.edgeLengths.length; i++) {
          var id0 = this.edgeIds[2 * i];
          var id1 = this.edgeIds[2 * i + 1];
          this.edgeLengths[i] = Math.sqrt(
            vecDistSquared(this.pos, id0, this.pos, id1)
          );
        }
      }

      preSolve(dt, gravity) {
        const backWallPosition = -0.3;
        const frontWallPosition = 0.3;
        const leftWallPosition = -1.0;
        const rightWallPosition = 1.0;

        for (var i = 0; i < this.numParticles; i++) {
          if (this.invMass[i] == 0.0) continue;

          vecAdd(this.vel, i, gravity, 0, dt);
          vecCopy(this.prevPos, i, this.pos, i);
          vecAdd(this.pos, i, this.vel, i, dt);

          var y = this.pos[3 * i + 1];
          // const floorHeight = -1.35;
          const floorHeight = -1.89;
          if (y < floorHeight) {
            vecCopy(this.pos, i, this.prevPos, i);
            this.pos[3 * i + 1] = floorHeight;

            if (!this.hasTouchedFloor) {
              // if (!soundSplash.isPlaying) {
              soundSplash.play();
              // }
              this.hasTouchedFloor = true;
              setTimeout(() => {
                this.edgeCompliance = 23.0;

                this.ajustarValores(0.1);
                activarHover();
                setStartThirdAnimation(false);
                // setHideHand(false);
                // setFinishAnimation(true);
              }, 1800);
            }
          } else {
          }

          var z = this.pos[3 * i + 2];
          if (z < backWallPosition) {
            vecCopy(this.pos, i, this.prevPos, i);
            this.pos[3 * i + 2] = backWallPosition;
          }

          if (z > frontWallPosition) {
            vecCopy(this.pos, i, this.prevPos, i);
            this.pos[3 * i + 2] = frontWallPosition;
          }

          var x = this.pos[3 * i];
          if (x < leftWallPosition) {
            vecCopy(this.pos, i, this.prevPos, i);
            this.pos[3 * i] = leftWallPosition;
          }

          if (x > rightWallPosition) {
            vecCopy(this.pos, i, this.prevPos, i);
            this.pos[3 * i] = rightWallPosition;
          }
        }
      }

      solve(dt) {
        this.solveEdges(this.edgeCompliance, dt);
        this.solveVolumes(this.volCompliance, dt);
      }

      postSolve(dt) {
        for (var i = 0; i < this.numParticles; i++) {
          if (this.invMass[i] == 0.0) continue;
          vecSetDiff(this.vel, i, this.pos, i, this.prevPos, i, 1.0 / dt);
        }
      }

      solveEdges(compliance, dt) {
        var alpha = compliance / dt / dt;

        for (var i = 0; i < this.edgeLengths.length; i++) {
          var id0 = this.edgeIds[2 * i];
          var id1 = this.edgeIds[2 * i + 1];
          var w0 = this.invMass[id0];
          var w1 = this.invMass[id1];
          var w = w0 + w1;
          if (w == 0.0) continue;

          vecSetDiff(this.grads, 0, this.pos, id0, this.pos, id1);
          var len = Math.sqrt(vecLengthSquared(this.grads, 0));
          if (len == 0.0) continue;
          vecScale(this.grads, 0, 1.0 / len);
          var restLen = this.edgeLengths[i];
          var C = len - restLen;
          var s = -C / (w + alpha);
          vecAdd(this.pos, id0, this.grads, 0, s * w0);
          vecAdd(this.pos, id1, this.grads, 0, -s * w1);
        }
      }

      solveVolumes(compliance, dt) {
        var alpha = compliance / dt / dt;

        for (var i = 0; i < this.numTets; i++) {
          var w = 0.0;

          for (var j = 0; j < 4; j++) {
            var id0 = this.tetIds[4 * i + this.volIdOrder[j][0]];
            var id1 = this.tetIds[4 * i + this.volIdOrder[j][1]];
            var id2 = this.tetIds[4 * i + this.volIdOrder[j][2]];

            vecSetDiff(this.temp, 0, this.pos, id1, this.pos, id0);
            vecSetDiff(this.temp, 1, this.pos, id2, this.pos, id0);
            vecSetCross(this.grads, j, this.temp, 0, this.temp, 1);
            vecScale(this.grads, j, 1.0 / 6.0);

            w +=
              this.invMass[this.tetIds[4 * i + j]] *
              vecLengthSquared(this.grads, j);
          }
          if (w == 0.0) continue;

          var vol = this.getTetVolume(i);
          var restVol = this.restVol[i];
          var C = vol - restVol;
          var s = -C / (w + alpha);

          for (var j = 0; j < 4; j++) {
            var id = this.tetIds[4 * i + j];
            vecAdd(this.pos, id, this.grads, j, s * this.invMass[id]);
          }
        }
      }

      endFrame() {
        this.updateTetMesh();
        this.updateVisMesh();
      }

      updateTetMesh() {
        const positions = this.tetMesh.geometry.attributes.position.array;

        for (let i = 0; i < this.pos.length; i++) positions[i] = this.pos[i];
        this.tetMesh.geometry.attributes.position.needsUpdate = true;
        this.tetMesh.geometry.computeBoundingSphere();
      }

      updateVisMesh() {
        const positions = this.visMesh.geometry.attributes.position.array;

        var nr = 0;

        for (let i = 0; i < this.numVisVerts; i++) {
          var tetNr = this.skinningInfo[nr++] * 4;
          if (tetNr < 0) {
            nr += 3;
            continue;
          }
          var b0 = this.skinningInfo[nr++];
          var b1 = this.skinningInfo[nr++];
          var b2 = this.skinningInfo[nr++];
          var b3 = 1.0 - b0 - b1 - b2;
          var id0 = this.tetIds[tetNr++];
          var id1 = this.tetIds[tetNr++];
          var id2 = this.tetIds[tetNr++];
          var id3 = this.tetIds[tetNr++];

          vecSetZero(positions, i);

          vecAdd(positions, i, this.pos, id0, b0);

          vecAdd(positions, i, this.pos, id1, b1);

          vecAdd(positions, i, this.pos, id2, b2);

          vecAdd(positions, i, this.pos, id3, b3);
        }
        this.visMesh.geometry.computeVertexNormals();
        this.visMesh.geometry.attributes.position.needsUpdate = true;
        this.visMesh.geometry.computeBoundingSphere();

        if (!this.firstPosition) {
          this.firstPosition = new Float32Array([...positions]);
        }
      }

      // squash() {
      //   for (var i = 0; i < this.numParticles; i++) {
      //     this.pos[3 * i + 1] = 0.5;
      //   }
      //   this.endFrame();
      // }

      startGrab(pos) {
        var p = [pos.x, pos.y, pos.z];
        this.originalPos = p;
        var minD2 = Number.MAX_VALUE;
        this.grabId = -1;
        for (let i = 0; i < this.numParticles; i++) {
          var d2 = vecDistSquared(p, 0, this.pos, i);
          if (d2 < minD2) {
            minD2 = d2;
            this.grabId = i;
          }
        }

        if (this.grabId >= 0) {
          this.grabInvMass = this.invMass[this.grabId];
          this.invMass[this.grabId] = 0.0;

          vecCopy(this.pos, this.grabId, p, 0);
        }
      }

      moveGrabbed(pos, vel) {
        if (this.grabId >= 0) {
          var p = [pos.x, pos.y, pos.z];
          vecCopy(this.pos, this.grabId, p, 0);
        }
      }

      endGrab(pos, vel) {
        if (this.grabId >= 0) {
          this.invMass[this.grabId] = this.grabInvMass;
          var v = [vel.x, vel.y, vel.z];
          vecCopy(this.vel, this.grabId, v, 0);
        }
        this.grabId = -1;
      }
      ajustarValores(velocidad = 0.01) {
        if (!this.firstPosition) return;
        // velocidad = 0.1;

        const animar = () => {
          // let completado = true;

          for (let i = 0; i < this.firstPosition.length; i++) {
            if (this.pos[i] !== this.firstPosition[i]) {
              // completado = false;
              const diferencia = this.firstPosition[i] - this.pos[i];
              this.pos[i] +=
                Math.abs(diferencia) * velocidad * Math.sign(diferencia);

              if (Math.abs(diferencia) < 0.05) {
                this.pos[i] = this.firstPosition[i];
              }
            }
          }

          // Si no hemos completado la animación, solicitamos otro frame de animación
          // if (!completado) {
          window.requestAnimationFrame(animar);
          // }
        };

        animar();
      }

      // ajustarValores(velocidad = 0.1) {
      //   if (!this.firstPosition) return;

      //   setInterval(() => {
      //     //   let completado = true;

      //     for (let i = 0; i < this.firstPosition.length; i++) {
      //       if (this.pos[i] !== this.firstPosition[i]) {
      //         //   completado = false;
      //         const diferencia = this.firstPosition[i] - this.pos[i];

      //         // this.pos[i] +=
      //         //   Math.abs(diferencia) * velocidad * Math.sign(diferencia);
      //         this.pos[i] +=
      //           Math.abs(diferencia) *velocidad * Math.sign(diferencia);

      //         if (Math.abs(diferencia) < 0.01) {
      //           this.pos[i] = this.firstPosition[i];
      //         }
      //       }
      //     }

      //     // requestAnimationFrame(this.ajustarValores(velocidad))
      //     // if (completado) {
      //     //   clearInterval(intervalo);
      //     // }
      //     // }, 16); // Ejecuta aproximadamente 60 veces por segundo.
      //   }, 16); // Ejecuta aproximadamente 60 veces por segundo.
      // }
    }

    // ------------------------------------------------------------------
    async function initPhysics() {
      const gltfLoader = new GLTFLoader();

      // const robot = await gltfLoader.loadAsync("/models/robot4.glb");
      // console.log("robot", robot);
      // const model = robot.scene;
      // const geometry = model.children[0].geometry;
      // let verts = geometry.getAttribute("position").array;
      // let triIds = geometry.getIndex().array;
      // const robotGLB = {
      //   verts: [...verts],
      //   triIds: [...triIds],
      // };
      // const material = model.children[0].material;
      // console.log("material", material);
      var body = new SoftBody(LetterTetMesh2, LetterVisTetMesh2, gThreeScene);
      gPhysicsScene.objects.push(body);
      if (document.getElementById("complianceSlider")) {
        document.getElementById("complianceSlider").oninput = function () {
          for (var i = 0; i < gPhysicsScene.objects.length; i++)
            gPhysicsScene.objects[i].edgeCompliance = this.value * 1.0;
        };
      }

      if (refActivateGUI.current) {
      }
      // console.warn("num Tets", body.numTets);
      // console.warn("tris visMesh", LetterVisTetMesh2.triIds.length / 3);
      // console.warn("verts visMesh", LetterVisTetMesh2.verts.length / 3);

      return body;
    }

    // ------------------------------------------------------------------
    function simulate() {
      if (gPhysicsScene.paused) return;
      var sdt = gPhysicsScene.dt / gPhysicsScene.numSubsteps;

      for (var step = 0; step < gPhysicsScene.numSubsteps; step++) {
        for (var i = 0; i < gPhysicsScene.objects.length; i++)
          gPhysicsScene.objects[i].preSolve(sdt, gPhysicsScene.gravity);

        for (var i = 0; i < gPhysicsScene.objects.length; i++)
          gPhysicsScene.objects[i].solve(sdt);

        for (var i = 0; i < gPhysicsScene.objects.length; i++)
          gPhysicsScene.objects[i].postSolve(sdt);
      }
      for (var i = 0; i < gPhysicsScene.objects.length; i++)
        gPhysicsScene.objects[i].endFrame();

      gGrabber.increaseTime(gPhysicsScene.dt);
    }

    // ------------------------------------------

    function initThreeScene() {
      // grabber

      gGrabber = new Grabber();
    }

    class Grabber {
      constructor() {
        this.raycaster = new THREE.Raycaster();
        this.raycaster.layers.set(1);
        this.raycaster.params.Line.threshold = 0.1;
        this.physicsObject = null;
        this.distance = 0.0;
        this.prevPos = new THREE.Vector3();
        this.vel = new THREE.Vector3();
        this.time = 0.0;
        this.firstTry = false;
      }
      increaseTime(dt) {
        this.time += dt;
      }
      updateRaycaster(x, y) {
        var rect = gRenderer.domElement.getBoundingClientRect();
        this.mousePos = new THREE.Vector2();
        this.mousePos.x = ((x - rect.left) / rect.width) * 2 - 1;
        this.mousePos.y = -((y - rect.top) / rect.height) * 2 + 1;
        this.raycaster.setFromCamera(this.mousePos, gCamera);
      }
      start(x, y) {
        this.physicsObject = null;
        this.updateRaycaster(x, y);
        var intersects = this.raycaster.intersectObjects(gThreeScene.children);
        if (intersects.length > 0) {
          var obj = intersects[0].object.userData;
          if (obj) {
            this.physicsObject = obj;
            this.distance = intersects[0].distance;
            var pos = this.raycaster.ray.origin.clone();
            pos.addScaledVector(this.raycaster.ray.direction, this.distance);

            // Ajustar la posición por la escala, la posición y la rotación del objeto
            pos.sub(obj.visMesh.position).divide(obj.visMesh.scale);
            pos.applyQuaternion(obj.visMesh.quaternion.clone().invert());

            this.physicsObject.startGrab(pos);
            this.prevPos.copy(pos);
            this.vel.set(0.0, 0.0, 0.0);
            this.time = 0.0;
            if (!soundSlip.isPlaying) {
              soundSlip.play();
            }
            if (!this.firstTry) {
              this.firstTry = true;
              setHideHand(true);
              setRemoveHand(true);
            }
            // if (refHand.current.style.display !== "none") {
            //   refHand.current.style.display = "none";
            // }
            // if (gPhysicsScene.paused) run();
          }
        }
      }
      move(x, y) {
        if (this.physicsObject) {
          this.updateRaycaster(x, y);
          var pos = this.raycaster.ray.origin.clone();
          pos.addScaledVector(this.raycaster.ray.direction, this.distance);

          // Ajustar la posición por la escala, la posición y la rotación del objeto
          pos
            .sub(this.physicsObject.visMesh.position)
            .divide(this.physicsObject.visMesh.scale);
          pos.applyQuaternion(
            this.physicsObject.visMesh.quaternion.clone().invert()
          );

          this.vel.copy(pos);
          this.vel.sub(this.prevPos);
          if (this.time > 0.0) this.vel.divideScalar(this.time);
          else this.vel.set(0.0, 0.0, 0.0);
          this.prevPos.copy(pos);
          this.time = 0.0;

          this.physicsObject.moveGrabbed(pos, this.vel);
        }
      }
      end(x, y) {
        if (this.physicsObject) {
          this.physicsObject.endGrab(this.prevPos, this.vel);
          this.physicsObject = null;
        }
      }
    }

    let lastX = 0;
    let lastY = 0;

    // let velocityFactor = 0.32;
    // let velocityFactor = 0.55;
    // let velocityFactor = 0.35;
    let velocityFactor = 0.3;
    // if (refActivateGUI.current) {
    // document
    //   .querySelector("#input-velocity")
    //   .addEventListener("onchange", () => {
    //     velocityFactor = document.querySelector("#input-velocity").value;
    //   });
    if (document.getElementById("input-velocity")) {
      document.getElementById("input-velocity").oninput = function () {
        velocityFactor = this.value;
      };
    }
    // }
    // if (refActivateGUI.current) {
    //   document.getElementById("input-velocity").oninput = function () {
    //     velocityFactor = this.value;
    //   };
    // }
    function onPointerMove(evt) {
      let clientX, clientY;
      if (evt.touches) {
        clientX = evt.touches[0].clientX;
        clientY = evt.touches[0].clientY;
      } else {
        clientX = evt.clientX;
        clientY = evt.clientY;
      }

      const directionX = clientX - lastX > 0 ? "derecha" : "izquierda";
      const directionY = clientY - lastY > 0 ? "abajo" : "arriba";

      const distanceX = Math.abs(clientX - lastX);
      const distanceY = Math.abs(clientY - lastY);

      const baseDeltaTime = 0.0166667; // Aproximadamente 1/60 segundos

      const velocityX =
        (distanceX / baseDeltaTime) *
        velocityFactor *
        (baseDeltaTime / refDeltaVelocity.current) *
        0.1;

      const velocityY =
        (distanceY / baseDeltaTime) *
        velocityFactor *
        (baseDeltaTime / refDeltaVelocity.current) *
        0.1;

      const moveX = Math.max(1, velocityX * 10);
      const moveY = Math.max(1, velocityY * 10);

      lastX = clientX;
      lastY = clientY;

      gGrabber.start(lastX, lastY);
      let derecha;
      let abajo;
      if (directionX === "derecha") {
        derecha = lastX + moveX;
        if (directionY === "abajo") {
          abajo = lastY + moveY;
          gGrabber.move(derecha, abajo);
        } else {
          abajo = lastY - moveY;
          gGrabber.move(derecha, abajo);
        }
      } else {
        derecha = lastX - moveX;
        if (directionY === "abajo") {
          abajo = lastY + moveY;
          gGrabber.move(derecha, abajo);
        } else {
          abajo = lastY - moveY;
          gGrabber.move(derecha, abajo);
        }
      }
      gGrabber.end();
    }
    //
    // let refDeltaVelocity = { current: 1.0 };
    // const maxMove = 100; // Limitar el movimiento máximo

    // function onPointerMove(evt) {
    //   let clientX, clientY;
    //   if (evt.touches) {
    //     clientX = evt.touches[0].clientX;
    //     clientY = evt.touches[0].clientY;
    //   } else {
    //     clientX = evt.clientX;
    //     clientY = evt.clientY;
    //   }
    //   const directionX = clientX - lastX > 0 ? "derecha" : "izquierda";
    //   const directionY = clientY - lastY > 0 ? "abajo" : "arriba";

    //   const distanceX = Math.abs(clientX - lastX);
    //   const distanceY = Math.abs(clientY - lastY);

    //   const baseDeltaTime = 0.0166667; // Aproximadamente 1/60 segundos

    //   const velocityX =
    //     (distanceX / baseDeltaTime) *
    //     velocityFactor *
    //     (baseDeltaTime / refDeltaVelocity.current) *
    //     0.1;

    //   const velocityY =
    //     (distanceY / baseDeltaTime) *
    //     velocityFactor *
    //     (baseDeltaTime / refDeltaVelocity.current) *
    //     0.1;

    //   const moveX = Math.min(Math.max(1, velocityX * 10), maxMove);
    //   const moveY = Math.min(Math.max(1, velocityY * 10), maxMove);

    //   lastX = clientX;
    //   lastY = clientY;

    //   gGrabber.start(lastX, lastY);
    //   let derecha;
    //   let abajo;
    //   if (directionX === "derecha") {
    //     derecha = lastX + moveX;
    //     if (directionY === "abajo") {
    //       abajo = lastY + moveY;
    //       gGrabber.move(derecha, abajo);
    //     } else {
    //       abajo = lastY - moveY;
    //       gGrabber.move(derecha, abajo);
    //     }
    //   } else {
    //     derecha = lastX - moveX;
    //     if (directionY === "abajo") {
    //       abajo = lastY + moveY;
    //       gGrabber.move(derecha, abajo);
    //     } else {
    //       abajo = lastY - moveY;
    //       gGrabber.move(derecha, abajo);
    //     }
    //   }
    //   gGrabber.end();
    // }

    // Asegurarse de adjuntar el evento de movimiento del mouse correctamente
    // document.addEventListener("mousemove", onPointerMove);
    // document.addEventListener("touchmove", onPointerMove);
    // Nueva función para activar el hover
    function activarHover() {
      document.addEventListener("mousemove", onPointerMove);
      document.addEventListener("touchmove", onPointerMove);
    }
    // document.removeEventListener("mousemove", onPointerMove);
    // document.removeEventListener("touchmove", onPointerMove);

    // function onWindowResize() {
    //   const width = window.innerWidth;
    //   const height = window.innerHeight;
    //   gCamera.aspect = width / height;
    //   gCamera.updateProjectionMatrix();
    //   gRenderer.setSize(width, height);
    //   // if (width < 320) {
    //   //   gCamera.position.y = 3.0;
    //   // } else if (width < 441) {
    //   //   gCamera.position.y = 2.6;
    //   // } else {
    //   //   gCamera.position.y = 2.0;
    //   // }
    // }

    function run() {
      gPhysicsScene.paused = !gPhysicsScene.paused;
    }

    function update() {
      // gRenderer.render(gThreeScene, gCamera);
      // stats?.update();
      // gComposer.render();
      simulate();
      // onWindowResize();
      // if (gCameraControl) {
      //   gCameraControl.update();
      // }
      requestAnimationFrame(update);
    }

    initThreeScene();
    initPhysics();
    simulate();
    update();

    setLoading(false);
    // setTimeout(() => {
    //   gPhysicsScene.paused = false;
    // }, 3000);
  }, []);
  return null;
};

export default Init;
